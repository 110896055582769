import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import BaseHeader from '../BaseHeader'

const LotCreateHeader: React.FC = () => {
  const { i18n } = useLanguage()

  return <BaseHeader title={i18n.t('TRUNKS')} />
}

export default LotCreateHeader
