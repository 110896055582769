import { IM, useModalController, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleSheet } from 'react-native'

import PressableIcon from '../../components/PressableIcon'
import { useDataByPackage } from '../../contexts/DataContext'
import { usePackage } from '../../hooks/usePackage'
import ClosePackageLotModal from '../../modals/ClosePackageLotModal'
import { ThemeColorExpanded } from '../../types'
import { PackageType } from '../../types/types'
import { getConnectedDevices } from '../../utils/bluetoothUtils'
import { printPackage } from '../../utils/printerUtils'
import BaseHeader from '../BaseHeader'
import DevicesModal from '../Devices/DevicesModal'

type PackageMeasureHeaderProps = {
  packageId: string
  packageType?: PackageType
  onSwitchMode: () => void
}

const PackageMeasureHeader: React.FC<PackageMeasureHeaderProps> = ({ packageId, packageType, onSwitchMode }: PackageMeasureHeaderProps) => {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { pkg, setPackage } = usePackage(packageId)
  const controller = useModalController()

  const devicesModalController = useModalController()

  const { wood, typology, grade, quality, customer } = useDataByPackage(pkg)

  const navigation = useNavigation()

  const openConfirmCloseModal = () => {
    controller.show()
  }

  const handleClosePackage = () => {
    setPackage({ ...pkg, closed: true })
    navigation.goBack()
  }

  const handleSwipeMode = () => {
    onSwitchMode()
  }

  const getBluetoothConnectedDevices = async () => {
    return await getConnectedDevices()
  }

  const handlePrint = () => {
    if (!pkg) return

    getBluetoothConnectedDevices()
      .then(connectedDevices => {
        const printerDevices = connectedDevices.filter(device => device.name && device.name.includes('Printer'))
        if (printerDevices.length > 0) {
          printerDevices.forEach(device => {
            printPackage(device.id, pkg, customer, wood, typology, grade, quality).catch(console.error)
          })
        } else {
          devicesModalController.show()
        }
      })
      .catch(console.error)
  }

  const rightComponent = (
    <IM.View style={styles.container}>
      <PressableIcon icon={['fal', 'print']} size={21} color={theme.header.main.text.primary} onPress={handlePrint} />
      {packageType?.value === 'TableToTable' && (
        <PressableIcon icon={['fal', 'expand']} size={21} color={theme.header.main.text.primary} onPress={handleSwipeMode} />
      )}
      <PressableIcon icon={['fal', 'check']} size={21} color={theme.header.main.text.primary} onPress={openConfirmCloseModal} />
      <DevicesModal controller={devicesModalController} />
      <ClosePackageLotModal onConfirmClose={handleClosePackage} controller={controller} />
    </IM.View>
  )

  return <BaseHeader title={pkg?.name ?? ''} rightComponent={rightComponent} />
}

export default PackageMeasureHeader

const styles = StyleSheet.create({
  container: { flexDirection: 'row' },
})
