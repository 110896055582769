import { IM, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import DateInput from '../../components/DateInput'
import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import useDeleteAlert from '../../hooks/useDeleteAlert'
import BoardCreateModal, { BoardCreateModalControllerData } from '../../modals/BoardCreateModal'
import PackageCreateModal, { PackageCreateModalControllerData } from '../../modals/PackageCreateModal'
import { ThemeColorExpanded } from '../../types'
import { Board, Package } from '../../types/types'

export interface PackagesTableData {
  packageId: number
  packageName: string
  woodName: string
  qualityName: string
  customerName: string
  typologyName: string
  measurerName: string
  sawmillName: string
  gradeName: string
  addDate: string
  packageBoards: Board[]
  commission: number
  totalBoards: number
  volume: number
  minLength: number
  maxLength: number
  minWidth: number
  maxWidth: number
}

export default function PackagesView() {
  const { colorScheme } = useTheme<ThemeColorExpanded>()
  const { i18n, language } = useLanguage()

  const [busy, setBusy] = useState(false)
  const [packages, setPackages] = useState<Package[]>([])
  const [fromDate, setFromDate] = useState<Date>(() => {
    const date = new Date()
    date.setDate(date.getDate() - 7)
    return date
  })
  const [toDate, setToDate] = useState<Date>(new Date())
  const deleteAlert = useDeleteAlert()
  const { alert } = useAlert()
  const createPackageModal = useModalController<PackageCreateModalControllerData>()
  const createBoardModal = useModalController<BoardCreateModalControllerData>()

  const packagesTableHeader: TableHeaderData<PackagesTableData>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'packageName' },
      { title: i18n.t('DATE'), property: 'addDate' },
      { title: i18n.t('COMMISSION'), property: 'commission' },
      { title: i18n.t('CUSTOMER'), property: 'customerName' },
      { title: i18n.t('WOOD'), property: 'woodName' },
      { title: i18n.t('QUALITY'), property: 'qualityName' },
      { title: i18n.t('GRADE'), property: 'gradeName' },
      { title: i18n.t('TYPOLOGY'), property: 'typologyName' },
      { title: i18n.t('MEASURER'), property: 'measurerName' },
      { title: i18n.t('SAWMILL'), property: 'sawmillName' },
      { title: i18n.t('MIN_LENGTH_S'), property: 'minLength' },
      { title: i18n.t('MAX_LENGTH_S'), property: 'maxLength' },
      { title: i18n.t('MIN_WIDTH_S'), property: 'minWidth' },
      { title: i18n.t('MAX_WIDTH_S'), property: 'maxWidth' },
      { title: i18n.t('VOLUME_S'), property: 'volume' },
      { title: i18n.t('BOARDS'), property: 'totalBoards' },
    ],
    [i18n]
  )

  const boardsTableHeader: TableHeaderData<Board>[] = useMemo(
    () => [
      { title: i18n.t('BOARD_N'), property: 'boardNumber' },
      { title: i18n.t('HEIGHT'), property: 'boardHeight' },
      { title: i18n.t('LENGTH'), property: 'boardLenght' },
      { title: i18n.t('WIDTH'), property: 'boardWidth' },
      { title: i18n.t('QUANTITY'), property: 'boardQuantity' },
      { title: i18n.t('VOLUME_S'), property: 'volume' },
    ],
    [i18n]
  )

  useEffect(() => {
    fetchPackages()
  }, [])

  useEffect(() => {
    fetchPackages()
  }, [fromDate, toDate])

  const packagesTableData: PackagesTableData[] = useMemo(() => {
    return packages.map(pkg => {
      const boardLengths = pkg.packageBoards.map(board => board.boardLenght)
      const boardWidths = pkg.packageBoards.map(board => board.boardWidth)

      const minLength = boardLengths.length > 0 ? Math.min(...boardLengths) : 0
      const maxLength = boardLengths.length > 0 ? Math.max(...boardLengths) : 0
      const minWidth = boardWidths.length > 0 ? Math.min(...boardWidths) : 0
      const maxWidth = boardWidths.length > 0 ? Math.max(...boardWidths) : 0

      return {
        packageId: pkg.packageId,
        packageName: pkg.packageName ?? '',
        gradeName: pkg.grade?.gradeName ?? '',
        woodName: pkg.wood?.woodName ?? '',
        qualityName: pkg.quality?.qualityName ?? '',
        customerName: pkg.customer?.customerName ?? '',
        typologyName: pkg.typology?.typologyName ?? '',
        measurerName: pkg.measurer?.measurerName ?? '',
        sawmillName: pkg.sawmill?.sawmillName ?? '',
        packageBoards: pkg.packageBoards,
        totalBoards: pkg.packageBoards.length,
        commission: pkg.commission,
        volume: pkg.volume,
        addDate: pkg.addDate
          ? new Date(pkg.addDate).toLocaleDateString('it-IT', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })
          : '',
        minLength,
        maxLength,
        minWidth,
        maxWidth,
      }
    })
  }, [packages, language])

  function fetchPackages() {
    setBusy(true)
    api
      .getPackagesFromDates({ startDate: fromDate, endDate: toDate })
      .then(response => {
        const mappedPackages = response.map((pkg: Package) => {
          const boardsWithVolume = pkg.packageBoards.map((board: Board) => {
            const heightInCm = board.boardHeight / 10
            const volumeInCm3 = board.boardWidth * heightInCm * board.boardLenght
            const volumeInM3 = (volumeInCm3 / 1000000) * board.boardQuantity

            return {
              ...board,
              volume: volumeInM3,
            }
          })

          const totalVolume = boardsWithVolume.reduce((sum, board) => sum + (board.volume || 0), 0)

          return {
            ...pkg,
            packageBoards: boardsWithVolume,
            volume: totalVolume,
          }
        })

        setPackages(mappedPackages)
      })
      .catch(console.error)
      .finally(() => setBusy(false))
  }

  function onNewPackageCreated() {
    createPackageModal.close()
    fetchPackages()
  }

  function handleNewPackagePress() {
    createPackageModal.show({})
  }

  function handlePackageEdit(pkg: PackagesTableData) {
    createPackageModal.show({ editPackageId: pkg.packageId })
  }

  function onNewBoardCreated() {
    createBoardModal.close()
    fetchPackages()
  }

  function handleBoardEdit(board: Board) {
    createBoardModal.show({ editBoardId: board.boardId })
  }

  function handleBoardDelete(board: Board) {
    console.log(
      'packages',
      packages.map(p => p.packageId)
    )
    console.log(
      'handleBoardDelete',
      packages.find(l => l.packageId === board.packageId)
    )
    const selectedBoard = packages.find(l => l.packageId === board.packageId)?.packageBoards.find(t => t.boardId === board.boardId)
    if (!selectedBoard?.boardId) {
      alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          if (!selectedBoard?.boardId) return
          console.log('selectedTrunk', selectedBoard)
          api
            .deleteBoard({ boardIds: [selectedBoard.boardId] })
            .then(() => {
              fetchPackages()
            })
            .catch(error => {
              console.log(error)
              alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
                {
                  text: i18n.t('OK'),
                  style: 'default',
                  onPress: () => {
                    return
                  },
                },
              ])
            })
        }
      })
      .catch(console.error)
  }

  function handleBoardAdd(parentRow: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    createBoardModal.show({ packageId: parentRow.packageId })
  }

  function handlePackageDelete(pkg: PackagesTableData) {
    const selectedPackage = packages.find(p => p.packageId === pkg.packageId)
    if (!selectedPackage?.packageId) {
      alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deletePackage({ packageId: selectedPackage.packageId })
            .then(() => {
              fetchPackages()
            })
            .catch(error => {
              console.log(error)
              alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
                {
                  text: i18n.t('OK'),
                  style: 'default',
                  onPress: () => {
                    return
                  },
                },
              ])
            })
        }
      })
      .catch(console.error)
  }

  return (
    <>
      {busy && (
        <IM.View style={styles.loadingContainer}>
          <IM.LoadingSpinner isVisible={true} />
        </IM.View>
      )}
      <ScrollView indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}>
        <IM.View spacing="all" style={styles.container}>
          <IM.View style={styles.datePickerRow}>
            <IM.Text>{i18n.t('FROM_DATE')}:</IM.Text>
            <DateInput
              value={fromDate.toDateString()}
              onChangeDate={date => date && setFromDate(date)}
              minimumDate={new Date(1899, 0, 1)}
              maximumDate={toDate}
              showCalendarButton={true}
            />

            <IM.Text style={{ marginLeft: 10 }}>{i18n.t('TO_DATE')}:</IM.Text>
            <DateInput
              value={toDate.toDateString()}
              onChangeDate={date => date && setToDate(date)}
              minimumDate={fromDate}
              maximumDate={new Date(2199, 0, 1)}
              showCalendarButton={true}
            />
          </IM.View>

          <TableWrapper
            tableContainerSpacing={'all'}
            headerData={packagesTableHeader}
            tableData={packagesTableData}
            subTableHeaderData={boardsTableHeader}
            subTableDataKey="packageBoards"
            hasPaging
            maxTableHeight={730}
            showNewItemRow
            onNewItemPress={handleNewPackagePress}
            hasEditAndDelete
            onEditPress={handlePackageEdit}
            onDeletePress={handlePackageDelete}
            flexArray={[1, 1, 1, 1, 1, 1]}
            subtableFlexArray={[1, 1, 1, 1, 1]}
            onSubtableEditPress={handleBoardEdit}
            onSubtableDeletePress={handleBoardDelete}
            onSubtableAddPress={handleBoardAdd}
          />
        </IM.View>
      </ScrollView>
      <PackageCreateModal controller={createPackageModal} onSaveCallback={onNewPackageCreated} />
      <BoardCreateModal controller={createBoardModal} onSaveCallback={onNewBoardCreated} />
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
  datePickerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: -20,
    marginLeft: 5,
    marginTop: 5,
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
})
