import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { PostSawmillRequest } from '../../apis/apiRequestTypes'
import { Sawmill } from '../../apis/apiResponseTypes'
import AnimatedButton from '../../components/AnimatedButton'
import GroupSpacer from '../../components/GroupSpacer'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import TextInput from '../../components/TextInput'
import { CREATE_SAWMILL_BUTTON_ID } from '../../constants/ButtonIds'
import { ThemeColorExpanded, UploadStatus } from '../../types'

const buttonAnimationValue = new Animated.Value(0)

export interface SawmillCreateViewProps {
  editId?: string
  onCreateSawmill?: () => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
}

const SawmillCreateView: React.FC<SawmillCreateViewProps & Props> = ({ editId, onCreateSawmill, onUploadStatus }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const [sawmill, setSawmill] = useState<Partial<PostSawmillRequest>>({})
  const [missingFields, setMissingFields] = useState<string[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<Partial<PostSawmillRequest>>({})

  useEffect(() => {
    if (editId) {
      loadEditSawmill().catch(console.error)
    }
  }, [editId])

  async function loadEditSawmill() {
    setIsLoading(true)
    await api
      .getSawmill({ sawmillId: Number(editId) })
      .then(response => {
        const editSawmill = {
          sawmillName: response.sawmillName,
          // Add other fields as necessary
        }
        setSawmill(editSawmill)
        setInitialValue(editSawmill)
      })
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  function handleChange(value: Partial<PostSawmillRequest>) {
    setSawmill(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    const requiredFields = [
      { value: sawmill.sawmillName, field: 'sawmillName' },
      // Add other required fields
    ]
    const notFilledFields = requiredFields.filter(field => !field.value).map(field => field.field)
    setMissingFields(notFilledFields)
    return notFilledFields.length === 0
  }

  const handlePress = () => {
    setShowMissingFields(true)
    setIsLoading(true)
    onUploadStatus('uploading')
    handleCreate().finally(() => {
      setIsLoading(false)
    })
  }

  const handleCreate = async () => {
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [{ text: i18n.t('OK'), style: 'default' }])
      return
    }

    const sawmillBody: Sawmill = {
      sawmillId: sawmill.sawmillId ?? 0,
      sawmillName: sawmill.sawmillName ?? '',
      sawmillCode: sawmill.sawmillCode ?? '',
      organizationId: sawmill.organizationId ?? 0,
      descriptions: {
        additionalProp1: sawmill.descriptions?.additionalProp1 ?? '',
        additionalProp2: sawmill.descriptions?.additionalProp2 ?? '',
        additionalProp3: sawmill.descriptions?.additionalProp3 ?? '',
      },
    }

    if (editId) {
      await api
        .putSawmill(sawmillBody)
        .then(() => {
          onUploadStatus('done')
          onCreateSawmill?.()
        })
        .catch(() => alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [{ text: i18n.t('OK'), style: 'default' }]))
      return
    }

    await api
      .postSawmill(sawmillBody)
      .then(() => {
        onUploadStatus('done')
        onCreateSawmill?.()
      })
      .catch(() => alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [{ text: i18n.t('OK'), style: 'default' }]))
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <TextInput
              required
              title={i18n.t('NAME')}
              placeholder={i18n.t('NAME')}
              value={sawmill.sawmillName ?? ''}
              onChangeText={value => handleChange({ sawmillName: value })}
              spacing={'all'}
              error={missingFields.includes('sawmillName')}
            />
            <GroupSpacer />
          </ScrollViewForm>

          {Platform.OS === 'web' ? (
            <IM.View style={styles.buttonContainer}>
              <PressableTextIcon
                icon={['fal', 'floppy-disk']}
                alignIcon="right"
                pressableStyle={styles.webButton}
                style={{ color: theme.button.icon }}
                iconColor={theme.button.icon}
                onPress={() => handlePress()}>
                {i18n.t('SAVE')}
              </PressableTextIcon>
            </IM.View>
          ) : (
            <AnimatedButton
              style={styles.button}
              id={CREATE_SAWMILL_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'check']}
              iconSize={50}
              onPress={() => handlePress()}
            />
          )}
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default SawmillCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
