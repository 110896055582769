import Tts from 'react-native-tts'

import { Wood } from '../apis/apiResponseTypes'
import { PackagesSettings } from '../types'
import { Board, Package, PackageType } from '../types/types'

type HandleInputChangeResult = {
  changedBoard: Board | undefined
  changePackage: boolean | undefined
  pkg: Package | undefined
}

export const packageUtils = {
  handleInputChange: (
    value: string,
    packageType: PackageType,
    packageSettings: PackagesSettings,
    currentBoard: Board,
    pkg: Package,
    inputType: 'fromInput' | 'fromMeter',
    enableVoice: boolean,
    wood?: Wood
  ): HandleInputChangeResult => {
    const match = value.match(/[\d.]+/)
    let numericValue = 0
    if (match) {
      numericValue = parseFloat(match[0])
    }
    if (isNaN(numericValue)) {
      return { changedBoard: undefined, changePackage: undefined, pkg: undefined }
    }

    switch (packageType.value) {
      case 'AutomaticLength':
        return packageUtils.handleAutomaticLength()
      case 'ChoppingBoard':
        return packageUtils.handleChoppingBoard(numericValue, packageSettings, currentBoard, pkg, enableVoice)
      case 'FirstLengths':
        return packageUtils.handleFirstLengths(numericValue, packageSettings, currentBoard, pkg, enableVoice)
      case 'Parquet':
        return packageUtils.handleParquet(numericValue, packageSettings, currentBoard, pkg, inputType)
      case 'Prismatic':
        return packageUtils.handlePrismatic(numericValue, packageSettings, currentBoard, pkg, inputType)
      case 'TableToTable':
        return packageUtils.handleTableToTable(numericValue, packageSettings, currentBoard, pkg, enableVoice)
      case 'Trasversal':
        return packageUtils.handleTrasversal(numericValue, packageSettings, currentBoard, pkg, inputType, enableVoice)
      case 'Weight':
        return packageUtils.handleWeight(numericValue, packageSettings, currentBoard, pkg, inputType, enableVoice, wood)
      default:
        return { changedBoard: undefined, changePackage: undefined, pkg: undefined }
    }
  },

  handleAutomaticLength(): HandleInputChangeResult {
    return { changedBoard: undefined, changePackage: true, pkg: undefined }
  },

  handleChoppingBoard(
    numericValue: number,
    packageSettings: PackagesSettings,
    currentBoard: Board,
    pkg: Package,
    enableVoice: boolean
  ): HandleInputChangeResult {
    if (currentBoard.boardLenght === 0 || packageSettings.enableRounding) {
      if (pkg?.settings.enableRounding) {
        Math.round((numericValue + (packageSettings.rounding.lengthRoundingDown - packageSettings.rounding.lengthIncreaseBy)) / 10) * 10
      }
      if (enableVoice) {
        Tts.speak(numericValue.toString())
      }
      return { changedBoard: { ...currentBoard, boardLenght: numericValue }, changePackage: true, pkg: undefined }
    } else if (currentBoard.boardWidth === 0) {
      if (pkg?.settings.enableRounding) {
        numericValue = packageUtils.roundAccordingToSetting(numericValue, packageSettings.rounding.widthRoundingDown)
      }
      if (enableVoice) {
        Tts.speak(numericValue.toString())
      }
      const updatedBoard = { ...currentBoard, boardWidth: numericValue }
      const newBoards = [...pkg.boards, updatedBoard]
      pkg.boards = newBoards

      return {
        changedBoard: {
          orderItemId: pkg.boards.length + 1,
          boardHeight: pkg.thickness || 0,
          boardLenght: updatedBoard.boardLenght ?? 0,
          boardWidth: 0,
          widthUm: packageSettings.enableRounding ? (pkg?.ums?.value?.substring(2, 4) ?? 'cm') : 'mm',
          lengthUm: packageSettings.enableRounding ? (pkg?.ums?.value?.substring(0, 2) ?? 'cm') : 'mm',
          addDate: new Date().toISOString(),
          boardNumber: pkg.boards.length + 1,
          boardQuantity: 1,
        },
        changePackage: undefined,
        pkg,
      }
    }
    return { changedBoard: currentBoard, changePackage: undefined, pkg }
  },

  handleFirstLengths(
    numericValue: number,
    packageSettings: PackagesSettings,
    currentBoard: Board,
    pkg: Package,
    enableVoice: boolean
  ): HandleInputChangeResult {
    if (pkg.boards.length > 0 && pkg.boards[pkg.boards.length - 1].boardWidth !== 0) {
      if (numericValue >= packageSettings.maxWidth) {
        return { changedBoard: undefined, changePackage: undefined, pkg: undefined }
      } else {
        currentBoard.boardWidth = numericValue
        if (enableVoice) {
          Tts.speak(numericValue.toString())
        }
        const index = pkg.boards.findIndex(board => board.orderItemId === currentBoard.orderItemId)
        return { changedBoard: pkg.boards[(index + 1) % pkg.boards.length], changePackage: undefined, pkg: undefined }
      }
    } else {
      if (numericValue >= packageSettings.maxWidth) {
        const updatedBoard = { ...currentBoard, boardLenght: numericValue }
        const newBoards = [...pkg.boards, updatedBoard]
        pkg.boards = newBoards
        if (enableVoice) {
          Tts.speak(numericValue.toString())
        }
        return {
          changedBoard: {
            orderItemId: pkg.boards.length + 1,
            boardHeight: pkg.thickness || 0,
            boardLenght: updatedBoard.boardLenght ?? 0,
            boardWidth: 0,
            widthUm: packageSettings.enableRounding ? (pkg.ums?.value?.substring(2, 4) ?? 'cm') : 'mm',
            lengthUm: packageSettings.enableRounding ? (pkg.ums?.value?.substring(0, 2) ?? 'cm') : 'mm',
            addDate: new Date().toISOString(),
            boardNumber: pkg.boards.length + 1,
            boardQuantity: 1,
          },
          changePackage: undefined,
          pkg,
        }
      } else {
        currentBoard.boardWidth = numericValue
        const index = pkg.boards.findIndex(board => board.orderItemId === currentBoard.orderItemId)
        return { changedBoard: pkg.boards[(index + 1) % pkg.boards.length], changePackage: undefined, pkg: undefined }
      }
    }
  },

  handleParquet(
    numericValue: number,
    packageSettings: PackagesSettings,
    currentBoard: Board,
    pkg: Package,
    inputType: 'fromInput' | 'fromMeter'
  ): HandleInputChangeResult {
    if (currentBoard.boardLenght === 0 || (packageSettings.enableRounding && packageSettings.maxWidth < numericValue)) {
      if (pkg?.settings.enableRounding) {
        Math.round((numericValue + (packageSettings.rounding.lengthRoundingDown - packageSettings.rounding.lengthIncreaseBy)) / 10) * 10
      }
      return { changedBoard: { ...currentBoard, boardLenght: numericValue }, changePackage: undefined, pkg: undefined }
    } else if (currentBoard.boardWidth === 0) {
      if (pkg?.settings.enableRounding) {
        numericValue = packageUtils.roundAccordingToSetting(numericValue, packageSettings.rounding.widthRoundingDown)
      }
    }
    if (currentBoard.boardQuantity === 0 && inputType === 'fromInput') {
      const updatedBoard = { ...currentBoard, boardQuantity: numericValue }
      const newBoards = [...pkg.boards, updatedBoard]
      pkg.boards = newBoards
      return {
        changedBoard: {
          orderItemId: pkg.boards.length + 1,
          boardHeight: pkg.thickness || 0,
          boardLenght: updatedBoard.boardLenght ?? 0,
          boardWidth: 0,
          widthUm: packageSettings.enableRounding ? (pkg.ums.value?.substring(2, 4) ?? 'cm') : 'mm',
          lengthUm: packageSettings.enableRounding ? (pkg.ums.value?.substring(0, 2) ?? 'cm') : 'mm',
          addDate: new Date().toISOString(),
          boardNumber: pkg.boards.length + 1,
          boardQuantity: updatedBoard.boardQuantity,
        },
        changePackage: undefined,
        pkg,
      }
    }
    return { changedBoard: currentBoard, changePackage: undefined, pkg: undefined }
  },

  handlePrismatic(
    numericValue: number,
    packageSettings: PackagesSettings,
    currentBoard: Board,
    pkg: Package,
    inputType: 'fromInput' | 'fromMeter'
  ): HandleInputChangeResult {
    if (currentBoard.boardLenght === 0 || (packageSettings.enableRounding && packageSettings.maxWidth < numericValue)) {
      if (pkg?.settings.enableRounding) {
        Math.round((numericValue + (packageSettings.rounding.lengthRoundingDown - packageSettings.rounding.lengthIncreaseBy)) / 10) * 10
      }
      return { changedBoard: { ...currentBoard, boardLenght: numericValue }, changePackage: undefined, pkg: undefined }
    } else if (currentBoard.boardWidth === 0) {
      if (pkg?.settings.enableRounding) {
        numericValue = packageUtils.roundAccordingToSetting(numericValue, packageSettings.rounding.widthRoundingDown)
      }
    }
    if (currentBoard.boardQuantity === 0 && inputType === 'fromInput') {
      const updatedBoard = { ...currentBoard, boardQuantity: numericValue }
      const newBoards = [...pkg.boards, updatedBoard]
      pkg.boards = newBoards
      return {
        changedBoard: {
          orderItemId: pkg.boards.length + 1,
          boardHeight: pkg.thickness || 0,
          boardLenght: updatedBoard.boardLenght ?? 0,
          boardWidth: 0,
          widthUm: packageSettings.enableRounding ? (pkg.ums.value?.substring(2, 4) ?? 'cm') : 'mm',
          lengthUm: packageSettings.enableRounding ? (pkg.ums.value?.substring(0, 2) ?? 'cm') : 'mm',
          addDate: new Date().toISOString(),
          boardNumber: pkg.boards.length + 1,
          boardQuantity: updatedBoard.boardQuantity,
        },
        changePackage: undefined,
        pkg,
      }
    }
    return { changedBoard: currentBoard, changePackage: undefined, pkg: undefined }
  },

  handleTableToTable(
    numericValue: number,
    packageSettings: PackagesSettings,
    currentBoard: Board,
    pkg: Package,
    enableVoice: boolean
  ): HandleInputChangeResult {
    if (currentBoard.boardLenght === 0 || (packageSettings.enableRounding && packageSettings.maxWidth < numericValue)) {
      if (pkg?.settings.enableRounding) {
        numericValue =
          Math.round((numericValue + (packageSettings.rounding.lengthRoundingDown - packageSettings.rounding.lengthIncreaseBy)) / 10) * 10
      }
      if (enableVoice) {
        Tts.speak(numericValue.toString())
      }
      return { changedBoard: { ...currentBoard, boardLenght: numericValue }, changePackage: undefined, pkg: undefined }
    } else if (currentBoard.boardWidth === 0) {
      if (pkg?.settings.enableRounding) {
        numericValue = packageUtils.roundAccordingToSetting(numericValue, packageSettings.rounding.widthRoundingDown)
      } else {
        numericValue = Math.round(numericValue)
      }
      if (enableVoice) {
        Tts.speak(currentBoard.boardLenght.toString() + 'per' + numericValue.toString())
      }
      const updatedBoard = { ...currentBoard, boardWidth: numericValue }
      const newBoards = [...pkg.boards, updatedBoard]
      pkg.boards = newBoards

      return {
        changedBoard: {
          orderItemId: pkg.boards.length + 1,
          boardHeight: pkg.thickness || 0,
          boardLenght: updatedBoard.boardLenght ?? 0,
          boardWidth: 0,
          widthUm: packageSettings.enableRounding ? (pkg.ums?.value?.substring(2, 4) ?? 'cm') : 'mm',
          lengthUm: packageSettings.enableRounding ? (pkg.ums?.value?.substring(0, 2) ?? 'cm') : 'mm',
          addDate: new Date().toISOString(),
          boardNumber: pkg.boards.length + 1,
          boardQuantity: 1,
        },
        changePackage: undefined,
        pkg,
      }
    }
    return { changedBoard: currentBoard, changePackage: undefined, pkg: undefined }
  },

  handleTrasversal(
    numericValue: number,
    packageSettings: PackagesSettings,
    currentBoard: Board,
    pkg: Package,
    inputType: 'fromInput' | 'fromMeter',
    enableVoice: boolean
  ): HandleInputChangeResult {
    if (currentBoard.boardLenght === 0 || (packageSettings.enableRounding && packageSettings.maxWidth < numericValue)) {
      if (pkg?.settings.enableRounding) {
        Math.round((numericValue + (packageSettings.rounding.lengthRoundingDown - packageSettings.rounding.lengthIncreaseBy)) / 10) * 10
      }
      if (enableVoice) {
        Tts.speak(numericValue.toString())
      }
      return { changedBoard: { ...currentBoard, boardLenght: numericValue }, changePackage: undefined, pkg: undefined }
    } else if (currentBoard.boardWidth === 0) {
      if (pkg?.settings.enableRounding) {
        numericValue = packageUtils.roundAccordingToSetting(numericValue, packageSettings.rounding.widthRoundingDown)
      }
    }
    if (currentBoard.boardQuantity === 0 && inputType === 'fromInput') {
      const updatedBoard = { ...currentBoard, boardQuantity: numericValue }
      const newBoards = [...pkg.boards, updatedBoard]
      pkg.boards = newBoards
      if (enableVoice) {
        Tts.speak(numericValue.toString())
      }
      return {
        changedBoard: {
          orderItemId: pkg.boards.length + 1,
          boardHeight: pkg.thickness || 0,
          boardLenght: updatedBoard.boardLenght ?? 0,
          boardWidth: 0,
          widthUm: packageSettings.enableRounding ? (pkg.ums.value?.substring(2, 4) ?? 'cm') : 'mm',
          lengthUm: packageSettings.enableRounding ? (pkg.ums.value?.substring(0, 2) ?? 'cm') : 'mm',
          addDate: new Date().toISOString(),
          boardNumber: pkg.boards.length + 1,
          boardQuantity: updatedBoard.boardQuantity,
        },
        changePackage: undefined,
        pkg,
      }
    }
    return { changedBoard: currentBoard, changePackage: undefined, pkg: undefined }
  },

  handleWeight(
    numericValue: number,
    packageSettings: PackagesSettings,
    currentBoard: Board,
    pkg: Package,
    inputType: 'fromInput' | 'fromMeter',
    enableVoice: boolean,
    wood?: Wood
  ): HandleInputChangeResult {
    if (inputType !== 'fromInput' || !wood) {
      return { changedBoard: undefined, changePackage: undefined, pkg: undefined }
    } else {
      if (currentBoard.boardWidth === 0) {
        const volume = numericValue / wood.unit
        const width = volume / ((pkg.thickness * currentBoard.boardLenght) / 10000)
        return { changedBoard: { ...currentBoard, boardWidth: width }, changePackage: undefined, pkg: undefined }
      } else if (currentBoard.boardQuantity === 0) {
        const updatedBoard = { ...currentBoard, boardQuantity: numericValue }
        const newBoards = [...pkg.boards, updatedBoard]
        pkg.boards = newBoards
        return {
          changedBoard: {
            orderItemId: pkg.boards.length + 1,
            boardHeight: pkg.thickness || 0,
            boardLenght: 300,
            boardWidth: 0,
            widthUm: pkg.settings.enableRounding ? (pkg.ums?.value?.substring(2, 4) ?? 'cm') : 'mm',
            lengthUm: pkg.settings.enableRounding ? (pkg.ums?.value?.substring(0, 2) ?? 'cm') : 'mm',
            addDate: new Date().toISOString(),
            boardNumber: pkg.boards.length + 1,
            boardQuantity: 0,
          },
          changePackage: undefined,
          pkg,
        }
      }
    }
    return { changedBoard: currentBoard, changePackage: undefined, pkg: undefined }
  },

  roundAccordingToSetting(value: number, roundingThreshold: number): number {
    const decimalPart = value % 1
    const roundedValue = decimalPart <= roundingThreshold ? Math.floor(value) : Math.ceil(value)
    return roundedValue
  },
}
