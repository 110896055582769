import { IM, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import TableUtils from '../../components/table/utils/TableUtils'
import { useAuthentication } from '../../contexts/AuthenticationContext'
import { useData } from '../../contexts/DataContext'
import useDeleteAlert from '../../hooks/useDeleteAlert'
import WoodTypeCreateModal from '../../modals/WoodTypeCreateModal'
import { WoodType } from '../../types/types'
import { WoodTypeCreateViewProps } from '../WoodTypeCreate/WoodTypeCreateView'

export default function WoodTypesView({
  setIsLoading,
  isLoading,
}: {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
}) {
  const { i18n } = useLanguage()
  const deleteAlert = useDeleteAlert()
  const { woodTypes, loadWoodTypes, woodTypesLoaded } = useData()
  const { isAdmin } = useAuthentication()

  const [woodTypeSearchValue, setWoodTypeSearchValue] = useState<string | undefined>(undefined)

  const createWoodTypeModal = useModalController<WoodTypeCreateViewProps>()

  useEffect(() => {
    if (woodTypesLoaded) return
    loadData()
  }, [])

  function loadData() {
    setIsLoading(true)
    loadWoodTypes(isAdmin)
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  const handleNewWoodTypePress = () => {
    createWoodTypeModal.show({})
  }

  const handleWoodTypeEdit = (woodType: WoodType) => {
    const selectedWoodType = woodTypes.find(c => c.woodTypeId === woodType.woodTypeId)
    createWoodTypeModal.show({ editId: selectedWoodType?.woodTypeId.toString() })
  }

  const handleWoodTypeDelete = (woodType: WoodType) => {
    const selectedWoodType = woodTypes.find(c => c.woodTypeId === woodType.woodTypeId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteWoodType({ woodTypeId: Number(selectedWoodType?.woodTypeId) })
            .then(() => {
              loadData()
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  const onNewWoodTypeCreated = (_woodType: WoodType) => {
    createWoodTypeModal.close()
    loadData()
  }

  const woodTypesTableHeader: TableHeaderData<WoodType>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'woodTypeName' },
      { title: i18n.t('CODE'), property: 'woodTypeCode' },
    ],
    [i18n]
  )

  const woodTypesTableData: WoodType[] = useMemo(() => {
    return TableUtils<WoodType>().filterData(woodTypes, woodTypeSearchValue)
  }, [woodTypes, woodTypeSearchValue])

  return (
    <>
      <IM.View spacing="all" style={styles.container}>
        <TableWrapper
          tableTitle={i18n.t('WOOD_TYPES')}
          tableContainerSpacing={'all'}
          headerData={woodTypesTableHeader}
          tableData={woodTypesTableData}
          maxTableHeight={750}
          showNewItemRow={true}
          hasPaging
          rowsPerPage={15}
          onNewItemPress={handleNewWoodTypePress}
          hasEditAndDelete={true}
          onEditPress={handleWoodTypeEdit}
          onDeletePress={handleWoodTypeDelete}
        />
      </IM.View>
      <WoodTypeCreateModal controller={createWoodTypeModal} onCreateWoodType={onNewWoodTypeCreated} />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
})
