import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import DateInput from '../../components/DateInput'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import TextInput from '../../components/TextInput'
import { ThemeColorExpanded } from '../../types'
import { Board } from '../../types/types'

export interface BoardCreateViewProps {
  editId?: number
  onSave?: () => void
  packageId?: number
}

type ValidateBoardField = 'boardNumber' | 'qualityId' | 'woodId' | 'addDate' | 'length' | 'diameter1' | 'diameter2' | 'reduction'
type RequiredField = { value: string | number | undefined; field: ValidateBoardField }

const now = new Date()

const year = now.getFullYear()
const month = (now.getMonth() + 1).toString().padStart(2, '0')
const day = now.getDate().toString().padStart(2, '0')
const formattedDate = `${year}-${month}-${day}`

const BoardCreateView: React.FC<BoardCreateViewProps> = ({ editId, onSave, packageId }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const [board, setBoard] = useState<Partial<Board>>({})
  const [missingFields, setMissingFields] = useState<ValidateBoardField[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<Partial<Board>>({})

  useEffect(() => {
    if (editId) {
      loadEditBoard().catch(console.error)
    }
  }, [editId])

  async function loadEditBoard() {
    setIsLoading(true)
    await api
      .getBoard({ boardId: Number(editId) })
      .then(response => {
        const editBoard = {
          boardNumber: response.boardNumber,
          addDate: response.addDate,
          packageId: response.packageId,
          boardId: response.boardId,
        }
        setBoard(editBoard)
        setInitialValue(editBoard)
      })
      .catch(console.error)
      .then(() => setIsLoading(false))
  }

  function handleChange(value: Partial<Board>) {
    setBoard(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    const requiredFields: RequiredField[] = [
      { value: board.boardNumber, field: 'boardNumber' },
      { value: board.addDate, field: 'addDate' },
    ]
    const notFilledFields = requiredFields.filter(field => field.value === undefined || field.value === '').map(field => field.field)
    setMissingFields(notFilledFields)
    return notFilledFields.length === 0
  }

  useEffect(() => {
    if (!showMissingFields) return
    if (!validateForm()) return
  }, [board, showMissingFields])

  const handlePress = () => {
    setShowMissingFields(true)
    setIsLoading(true)
    handleCreate()
      .catch(console.error)
      .finally(() => {
        setIsLoading(false)
        if (onSave) onSave()
      })
  }

  const handleCreate = async () => {
    //VALIDATION
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }

    const boardBody: Board = {
      boardId: board.boardId ?? 0,
      packageId: packageId ?? board.packageId ?? 0,
      boardNumber: board.boardNumber ?? 0,
      addDate: board.addDate ?? '',
      orderItemId: board.orderItemId ?? 0,
      boardHeight: board.boardHeight ?? 0,
      boardWidth: board.boardWidth ?? 0,
      boardLenght: board.boardLenght ?? 0,
      boardQuantity: board.boardQuantity ?? 0,
      widthUm: board.widthUm ?? '',
      lengthUm: board.lengthUm ?? '',
    }

    if (editId) {
      await api
        .putBoard(boardBody)
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
          alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
      return
    } else {
      await api
        .postBoard(boardBody)
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
          alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
    }
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <TextInput
              required
              title={i18n.t('BOARD_N')}
              placeholder={i18n.t('BOARD_N')}
              value={String(board.boardNumber ?? '')}
              onChangeText={value => handleChange({ boardNumber: Number(value) })}
              spacing={'all'}
              error={missingFields.includes('boardNumber')}
            />
            <DateInput
              containerStyle={IMLayout.flex.f1}
              title={i18n.t('DATE')}
              value={board.addDate || formattedDate}
              editable={false}
              onChangeDate={() => handleChange({ addDate: formattedDate })}
              spacing={['all']}
            />
            <TextInput
              required
              title={i18n.t('HEIGHT')}
              placeholder={i18n.t('HEIGHT')}
              value={String(board.boardHeight ?? '')}
              onChangeText={value => handleChange({ boardHeight: Number(value) })}
              spacing={'all'}
            />
            <TextInput
              required
              title={i18n.t('LENGTH')}
              placeholder={i18n.t('LENGTH')}
              value={String(board.boardLenght ?? '')}
              onChangeText={value => handleChange({ boardLenght: Number(value) })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('WIDTH')}
              placeholder={i18n.t('WIDTH')}
              value={String(board.boardWidth ?? '')}
              onChangeText={value => handleChange({ boardWidth: Number(value) })}
              spacing={'all'}
            />
            {/* <TextInput
    required
    title={i18n.t('BONUS_DIAMETER')}
    placeholder={i18n.t('BONUS_DIAMETER')}
    value={String(board.bonusDiameter ?? '')}
    onChangeText={value => handleChange({ bonusDiameter: Number(value) })}
    spacing={'all'}
    error={missingFields.includes('bonusDiameter')}
  />
  <TextInput
    required
    title={i18n.t('BONUS_LENGTH')}
    placeholder={i18n.t('BONUS_LENGTH')}
    value={String(board.bonusLength ?? '')}
    onChangeText={value => handleChange({ bonusLength: Number(value) })}
    spacing={'all'}
    error={missingFields.includes('bonusLength')}
  />
  <TextInput
    required
    title={i18n.t('BONUS_WOOD')}
    placeholder={i18n.t('BONUS_WOOD')}
    value={String(board.bonusWood ?? '')}
    onChangeText={value => handleChange({ bonusWood: Number(value) })}
    spacing={'all'}
    error={missingFields.includes('bonusWood')}
  />
  <TextInput
    title={i18n.t('LATITUDE')}
    placeholder={i18n.t('LATITUDE')}
    value={String(board.latitude ?? '')}
    onChangeText={value => handleChange({ latitude: Number(value) })}
    spacing={'all'}
  />
  <TextInput
    title={i18n.t('LONGITUDE')}
    placeholder={i18n.t('LONGITUDE')}
    value={String(board.longitude ?? '')}
    onChangeText={value => handleChange({ longitude: Number(value) })}
    spacing={'all'}
  /> */}
          </ScrollViewForm>

          <IM.View style={styles.buttonContainer}>
            <PressableTextIcon
              icon={['fal', 'floppy-disk']}
              alignIcon="right"
              pressableStyle={styles.webButton}
              style={{ color: theme.button.icon }}
              iconColor={theme.button.icon}
              onPress={() => handlePress()}>
              {i18n.t('SAVE')}
            </PressableTextIcon>
          </IM.View>
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default BoardCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
