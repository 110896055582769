import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import ReductionTypeCreateScreen from '../screens/ReductionTypeCreate/ReductionTypeCreateScreen'
import { ReductionTypeCreateViewProps } from '../screens/ReductionTypeCreate/ReductionTypeCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type ReductionTypeCreateModalProps = {
  controller: ModalController<ReductionTypeCreateViewProps>
} & Pick<ReductionTypeCreateViewProps, 'onCreateReductionType'>

export default function ReductionTypeCreateModal({ controller, onCreateReductionType }: ReductionTypeCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <ReductionTypeCreateScreen ref={ref} onCreateReductionType={onCreateReductionType} controller={controller} />
    </BaseServiceModal>
  )
}
