import { CardProps, IM, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { Board, PackageType } from '../../types/types'

export type PackageCardProps = { board: Board; type: PackageType } & CardProps

export default function PackageBoardCard({ board, type, style, ...cardProps }: PackageCardProps) {
  const { i18n } = useLanguage()

  return (
    <IM.Card {...cardProps} style={[styles.card, style]}>
      <IM.View style={styles.badgeContent}>
        <IM.View style={styles.column}>
          <IM.Text style={styles.labelLeft}>{i18n.t('BOARD_N') + ' ' + board.orderItemId.toString()}</IM.Text>
        </IM.View>
        <IM.View style={[styles.column]}>
          <IM.Text style={styles.labelRight}>
            {board.boardHeight.toString() + ' x ' + board.boardLenght.toString() + ' x ' + board.boardWidth.toString()}
            {(type.value === 'Prismatic' || type.value === 'Trasversal' || type.value === 'Parquet' || type.value === 'Weight') && (
              <IM.Text style={styles.labelRight}> * {board.boardQuantity.toString()}</IM.Text>
            )}
          </IM.Text>
        </IM.View>
      </IM.View>
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  badgeContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flex: 1,
    padding: 5,
  },
  labelLeft: {
    textAlign: 'left',
    // color: 'white',
  },
  labelRight: {
    textAlign: 'right',
    // color: 'white',
    fontWeight: 'bold',
    // fontSize: 16,
  },
  card: {
    marginBottom: 3,
  },
})
