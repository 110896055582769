import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import BaseHeader from '../BaseHeader'

const BoardCreateHeader: React.FC = () => {
  const { i18n } = useLanguage()

  return <BaseHeader title={i18n.t('BOARDS')} />
}

export default BoardCreateHeader
