import { useTheme } from '@infominds/react-native-components'
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack'
import React, { useMemo } from 'react'
import { Platform } from 'react-native'

import DrawerNavigator from '../components/Infominds/DrawerNavigator'
import TabNavigator from '../components/Infominds/TabNavigator'
import { useAuthentication } from '../contexts/AuthenticationContext'
import { useData } from '../contexts/DataContext'
import AllOrganizationsScreen from '../screens/AllOrganizations/AllOrganizationsScreen'
import AttributesScreen from '../screens/Attributes/AttributesScreen'
import LoginScreen from '../screens/Login/LoginScreen'
import LotMeasureScreen from '../screens/LotMeasure/LotMeasureScreen'
import LotsScreen from '../screens/Lots/LotsScreen'
import OrganizationScreen from '../screens/Organization/OrganizationScreen'
import PackagesScreen from '../screens/Package/PackagesScreen'
import PackageMeasureScreen from '../screens/PackageMeasure/PackageMeasureScreen'
import ReductionFormulasScreen from '../screens/ReductionFormulas/ReductionFormulasScreen'
import SettingsScreen from '../screens/settings/SettingsScreen'
import StatisticsScreen from '../screens/Statistics/StatisticsScreen'
import WoodTypesScreen from '../screens/WoodTypes/WoodTypesScreen'
import {
  AllOrganizationsStackParamList,
  AttributesStackParamList,
  BottomTabParamList,
  LotsStackParamList,
  OrganizationStackParamList,
  PackagesStackParamList,
  ReductionFormulasStackParamList,
  RootStackParamList,
  SettingsStackParamList,
  StatisticsStackParamList,
  TabNavigationScreen,
  WoodTypesStackParamList,
} from './types'

export const StackNavigationOptions: NativeStackNavigationOptions = {
  headerShown: false,
  animation: Platform.OS === 'ios' ? 'default' : 'fade',
}

export const BottomTabs = {
  allOrganizations: {
    id: 0,
    name: 'AllOrganizationsStack',
    component: AllOrganizationsStackNavigator,
    icon: ['fal', 'toolbox'],
    title: 'ALL_ORGANIZATIONS',
    iconSize: 20,
  },
  organization: {
    id: 1,
    name: 'OrganizationStack',
    component: OrganizationStackNavigator,
    icon: ['fal', 'sitemap'],
    title: 'ORGANIZATION',
    iconSize: 20,
  },
  attributes: {
    id: 2,
    name: 'AttributesStack',
    component: AttributesStackNavigator,
    icon: ['fal', 'list'],
    title: 'ATTRIBUTES',
    iconSize: 20,
  },
  woodTypes: {
    id: 3,
    name: 'WoodTypesStack',
    component: WoodTypesStackNavigator,
    icon: ['fal', 'fence'],
    title: 'WOOD_TYPES',
    iconSize: 20,
    options: { tabBarStyle: { display: 'none' } },
  },
  reductionFormulas: {
    id: 4,
    name: 'ReductionFormulasStack',
    component: ReductionFormulasStackNavigator,
    icon: ['fal', 'axe'],
    title: 'REDUCTION_FORMULAS',
    iconSize: 20,
    options: { tabBarStyle: { display: 'none' } },
  },
  packages: {
    id: 5,
    name: 'PackagesStack',
    component: PackagesStackNavigator,
    icon: ['fal', 'cube'],
    title: 'PACKAGES',
    iconSize: 20,
  },
  lots: {
    id: 6,
    name: 'LotsStack',
    component: LotsStackNavigator,
    icon: ['fal', 'tree'],
    title: 'LOTS',
    iconSize: 20,
    options: { tabBarStyle: { display: 'none' } },
  },
  statistics: {
    id: 7,
    name: 'StatisticsStack',
    component: StatisticsStackNavigator,
    icon: ['fal', 'chart-line'],
    title: 'STATISTICS',
    iconSize: 20,
    options: { tabBarStyle: { display: 'none' } },
  },

  settings: {
    id: 8,
    name: 'SettingsStack',
    component: SettingsStackNavigator,
    icon: ['fal', 'gear'],
    title: 'SETTINGS',
    iconSize: 20,
  },
} satisfies Record<string, TabNavigationScreen<keyof BottomTabParamList>>

const LotsStack = createNativeStackNavigator<LotsStackParamList>()
function LotsStackNavigator() {
  return (
    <LotsStack.Navigator screenOptions={{ headerShown: false }}>
      <LotsStack.Screen name="Lots" component={LotsScreen} />
    </LotsStack.Navigator>
  )
}

const PackagesStack = createNativeStackNavigator<PackagesStackParamList>()
function PackagesStackNavigator() {
  return (
    <PackagesStack.Navigator screenOptions={{ headerShown: false }}>
      <PackagesStack.Screen name="Packages" component={PackagesScreen} />
    </PackagesStack.Navigator>
  )
}

const AllOrganizationsStack = createNativeStackNavigator<AllOrganizationsStackParamList>()
function AllOrganizationsStackNavigator() {
  return (
    <AllOrganizationsStack.Navigator screenOptions={{ headerShown: false }}>
      <AllOrganizationsStack.Screen name="AllOrganizations" component={AllOrganizationsScreen} />
    </AllOrganizationsStack.Navigator>
  )
}

const OrganizationStack = createNativeStackNavigator<OrganizationStackParamList>()
function OrganizationStackNavigator() {
  return (
    <OrganizationStack.Navigator screenOptions={{ headerShown: false }}>
      <OrganizationStack.Screen name="Organization" component={OrganizationScreen} />
    </OrganizationStack.Navigator>
  )
}

const AttributesStack = createNativeStackNavigator<AttributesStackParamList>()
function AttributesStackNavigator() {
  return (
    <AttributesStack.Navigator screenOptions={{ headerShown: false }}>
      <AttributesStack.Screen name="Attributes" component={AttributesScreen} />
    </AttributesStack.Navigator>
  )
}

const StatisticsStack = createNativeStackNavigator<StatisticsStackParamList>()
function StatisticsStackNavigator() {
  return (
    <StatisticsStack.Navigator screenOptions={{ headerShown: false }}>
      <StatisticsStack.Screen name="Statistics" component={StatisticsScreen} />
    </StatisticsStack.Navigator>
  )
}

const SettingsStack = createNativeStackNavigator<SettingsStackParamList>()
function SettingsStackNavigator() {
  return (
    <SettingsStack.Navigator screenOptions={{ headerShown: false }}>
      <SettingsStack.Screen name="Settings" component={SettingsScreen} />
    </SettingsStack.Navigator>
  )
}

const WoodTypesStack = createNativeStackNavigator<WoodTypesStackParamList>()
function WoodTypesStackNavigator() {
  return (
    <WoodTypesStack.Navigator screenOptions={{ headerShown: false }}>
      <WoodTypesStack.Screen name="WoodTypes" component={WoodTypesScreen} />
    </WoodTypesStack.Navigator>
  )
}

const ReductionFormulasStack = createNativeStackNavigator<ReductionFormulasStackParamList>()
function ReductionFormulasStackNavigator() {
  return (
    <ReductionFormulasStack.Navigator screenOptions={{ headerShown: false }}>
      <ReductionFormulasStack.Screen name="ReductionFormulas" component={ReductionFormulasScreen} />
    </ReductionFormulasStack.Navigator>
  )
}

const screenOptions: NativeStackNavigationOptions = {
  headerShown: false,
  animation: Platform.OS === 'ios' ? 'default' : 'fade',
}

// ############################ Root Navigator ############################

const RootStack = createNativeStackNavigator<RootStackParamList>()
export function RootStackNavigator() {
  const { theme } = useTheme()
  const { isAdmin } = useAuthentication()
  const { defaultMenu } = useData()
  const initialRouteName = useMemo(() => {
    if (Platform.OS === 'web') {
      return isAdmin ? 'AllOrganizationsStack' : 'OrganizationStack'
    } else {
      return defaultMenu === 'Packages' ? 'PackagesStack' : 'LotsStack'
    }
  }, [isAdmin, defaultMenu])

  return (
    <RootStack.Navigator
      screenOptions={{
        ...screenOptions,
        navigationBarColor: theme.tabNavigator.background,
        gestureEnabled: Platform.OS !== 'web', // This is to enable the navigation event listener "beforeRemove" on web
      }}>
      <RootStack.Group>
        <RootStack.Screen name="BottomTab" initialParams={{ screen: initialRouteName }}>
          {() =>
            Platform.OS === 'web' ? (
              <DrawerNavigator
                screenOptions={{ lazy: true }} // set lazy to true to optimize performance. lazy = false is only required to navigate to stacks that are not mounted yet. Maybe a better solution can be found
              />
            ) : (
              <TabNavigator
                screenOptions={{ lazy: true }} // set lazy to true to optimize performance. lazy = false is only required to navigate to stacks that are not mounted yet. Maybe a better solution can be found
              />
            )
          }
        </RootStack.Screen>

        <RootStack.Screen name="PackagesMeasure" component={PackageMeasureScreen} />
        <RootStack.Screen name="LotsMeasure" component={LotMeasureScreen} />
      </RootStack.Group>
    </RootStack.Navigator>
  )
}

const LoginStack = createNativeStackNavigator<RootStackParamList>()
export function LoginStackNavigator() {
  const { theme } = useTheme()

  return (
    <LoginStack.Navigator
      screenOptions={{
        ...screenOptions,
        navigationBarColor: theme.tabNavigator.background,
        gestureEnabled: Platform.OS !== 'web', // This is to enable the navigation event listener "beforeRemove" on web
      }}>
      <LoginStack.Group>
        <LoginStack.Screen name="Login" component={LoginScreen} />
      </LoginStack.Group>
    </LoginStack.Navigator>
  )
}
