import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'

import PressableIcon from '../../components/PressableIcon'
import { Screen } from '../../components/screen/Screen'
import { useAuthentication } from '../../contexts/AuthenticationContext'
import { useData } from '../../contexts/DataContext'
import { ThemeColorExpanded } from '../../types'
import BaseHeader from '../BaseHeader'
import StatisticsView from './StatisticsView'

export default function StatisticsScreen() {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isAdmin } = useAuthentication()
  const { loadStatistics } = useData()
  const [isLoading, setIsLoading] = useState(false)

  function reloadData() {
    setIsLoading(true)
    loadStatistics(isAdmin)
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  return (
    <Screen>
      <Screen.Header>
        <BaseHeader
          title={i18n.t('STATISTICS')}
          rightComponent={<PressableIcon icon={['fal', 'arrow-rotate-left']} size={25} color={theme.header.main.text.primary} onPress={reloadData} />}
        />
      </Screen.Header>
      <Screen.Content>
        <StatisticsView setIsLoading={setIsLoading} isLoading={isLoading} />
      </Screen.Content>
    </Screen>
  )
}
