import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { PostCustomerRequest } from '../../apis/apiRequestTypes'
import { Customer } from '../../apis/apiResponseTypes'
import AnimatedButton from '../../components/AnimatedButton'
import GroupSpacer from '../../components/GroupSpacer'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import TextInput from '../../components/TextInput'
import { CREATE_CUSTOMER_BUTTON_ID } from '../../constants/ButtonIds'
import { ThemeColorExpanded, UploadStatus } from '../../types'

const buttonAnimationValue = new Animated.Value(0)

export interface CustomerCreateViewProps {
  editId?: string
  onCreateCustomer?: () => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
}

type ValidateCustomerField = 'customerName' | 'email' | 'phoneNumber' | 'address'
type RequiredField = { value: string | undefined; field: ValidateCustomerField }

const CustomerCreateView: React.FC<CustomerCreateViewProps & Props> = ({ editId, onCreateCustomer, onUploadStatus }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const [customer, setCustomer] = useState<Partial<PostCustomerRequest>>({})
  const [missingFields, setMissingFields] = useState<ValidateCustomerField[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<Partial<PostCustomerRequest>>({})

  useEffect(() => {
    if (editId) {
      loadEditCustomer().catch(console.error)
    }
  }, [editId])

  async function loadEditCustomer() {
    setIsLoading(true)
    await api
      .getCustomer({ customerId: Number(editId) })
      .then(response => {
        const editCustomer = {
          customerCode: response.customerCode,
          customerName: response.customerName,
          email: response.email,
          phoneNumber: response.phoneNumber,
          address: response.address,
          zip: response.zip,
          city: response.city,
          province: response.province,
          country: response.country,
          language: response.language,
          validUntil: response.validUntil,
          suspension: response.suspension,
          isSupplier: response.isSupplier,
        }
        setCustomer(editCustomer)
        setInitialValue(editCustomer)
      })
      .catch(console.error)
      .then(() => setIsLoading(false))
  }

  function handleChange(value: Partial<PostCustomerRequest>) {
    setCustomer(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    const requiredFields: RequiredField[] = [
      { value: customer.customerName, field: 'customerName' },
      { value: customer.email, field: 'email' },
      { value: customer.phoneNumber, field: 'email' },
      { value: customer.address, field: 'address' },
    ]
    const notFilledFields = requiredFields.filter(field => field.value === undefined || field.value === '').map(field => field.field)
    setMissingFields(notFilledFields)
    if (notFilledFields.length > 0) {
      onUploadStatus('mandatoryMissing')
    }
    return notFilledFields.length === 0
  }

  function haveValuesChanged() {
    return JSON.stringify(customer) !== JSON.stringify(initialValue)
  }

  useEffect(() => {
    if (haveValuesChanged()) {
      onUploadStatus('waiting')
    }
    if (!showMissingFields) return
    if (!validateForm()) return
  }, [customer, showMissingFields])

  const handlePress = () => {
    setShowMissingFields(true)
    setIsLoading(true)
    onUploadStatus('uploading')
    handleCreate()
      .catch(console.error)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCreate = async () => {
    //VALIDATION
    // check if all required fields are filled
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }

    const customerBody: Customer = {
      customerId: customer.customerId ?? 0,
      customerCode: customer.customerCode ?? '',
      organizationId: customer.organizationId ?? 0,
      customerName: customer.customerName ?? '***',
      email: customer.email ?? '',
      phoneNumber: customer.phoneNumber ?? '',
      address: customer.address ?? '',
      zip: customer.zip ?? '',
      city: customer.city ?? '',
      province: customer.province ?? '',
      country: customer.country ?? '',
      language: customer.language ?? '',
      validUntil: customer.validUntil ?? '',
      suspension: customer.suspension ?? '',
      isSupplier: customer.isSupplier ?? false,
    }

    //TODO: check return type of putCustomer
    //UPDATE customer
    if (editId) {
      await api
        .putCustomer(customerBody)
        .then(() => {
          onUploadStatus('done')
          onCreateCustomer?.()
        })
        .catch(error => {
          console.log(error)
          alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
      return
    }

    //CREATE customer
    await api
      .postCustomer(customerBody)
      .then(() => {
        onUploadStatus('done')
        onCreateCustomer?.()
      })
      .catch(error => {
        console.log(error)
        alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
          {
            text: i18n.t('OK'),
            style: 'default',
            onPress: () => {
              return
            },
          },
        ])
      })
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <TextInput
              required
              title={i18n.t('NAME')}
              placeholder={i18n.t('NAME')}
              value={customer.customerName ?? ''}
              onChangeText={value => handleChange({ customerName: value })}
              spacing={'all'}
              error={missingFields.includes('customerName')}
            />
            <TextInput
              required
              title={i18n.t('EMAIL')}
              placeholder={i18n.t('EMAIL')}
              value={customer.email ?? ''}
              onChangeText={value => handleChange({ email: value })}
              spacing={'all'}
              error={missingFields.includes('email')}
            />
            <TextInput
              required
              title={i18n.t('PHONE_NUMBER')}
              placeholder={i18n.t('PHONE_NUMBER')}
              value={customer.phoneNumber ?? ''}
              onChangeText={value => handleChange({ phoneNumber: value })}
              spacing={'all'}
              error={missingFields.includes('phoneNumber')}
            />

            <TextInput
              required
              title={i18n.t('ADDRESS')}
              placeholder={i18n.t('ADDRESS')}
              value={customer.address ?? ''}
              onChangeText={value => handleChange({ address: value })}
              spacing={'all'}
              error={missingFields.includes('address')}
            />

            <GroupSpacer />

            <TextInput
              title={i18n.t('CITY')}
              placeholder={i18n.t('CITY')}
              value={customer.city ?? ''}
              onChangeText={value => handleChange({ city: value })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('ZIP')}
              placeholder={i18n.t('ZIP')}
              value={customer.zip ?? ''}
              onChangeText={value => handleChange({ zip: value })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('PROVINCE')}
              placeholder={i18n.t('PROVINCE')}
              value={customer.province ?? ''}
              onChangeText={value => handleChange({ city: value })}
              spacing={'all'}
            />
          </ScrollViewForm>

          {Platform.OS === 'web' ? (
            <IM.View style={styles.buttonContainer}>
              <PressableTextIcon
                icon={['fal', 'floppy-disk']}
                alignIcon="right"
                pressableStyle={styles.webButton}
                style={{ color: theme.button.icon }}
                iconColor={theme.button.icon}
                onPress={() => handlePress()}>
                {i18n.t('SAVE')}
              </PressableTextIcon>
            </IM.View>
          ) : (
            <AnimatedButton
              style={styles.button}
              id={CREATE_CUSTOMER_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'check']}
              iconSize={50}
              onPress={() => handlePress()}
            />
          )}
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default CustomerCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
