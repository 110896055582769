import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'
import uuid from 'react-native-uuid'

import api from '../../apis/apiCalls'
import { PostLotRequest, PutLotRequest } from '../../apis/apiRequestTypes'
import AnimatedButton from '../../components/AnimatedButton'
import DateInput from '../../components/DateInput'
import NumberInput from '../../components/NumberInput'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import CustomerSelector from '../../components/selectors/CustomerSelector'
import LotTypeSelector from '../../components/selectors/LotTypeSelector'
import MeasurerSelector from '../../components/selectors/MeasurerSelector'
import OriginSelector from '../../components/selectors/OriginSelector'
import QualitySelector from '../../components/selectors/QualitySelector'
import SawmillSelector from '../../components/selectors/SawmillSelector'
import WoodSelector from '../../components/selectors/WoodSelector'
import Switch from '../../components/Switch'
import TextInput from '../../components/TextInput'
import { ADD_PACKAGE_BUTTON_ID } from '../../constants/ButtonIds'
import { STORAGE_KEYS } from '../../constants/keys'
import { useData } from '../../contexts/DataContext'
import { useLotCreation } from '../../contexts/LotCreationContext'
import { useSettings } from '../../contexts/SettingsContext'
import { useLots } from '../../hooks/useLots'
import { LotSettings, ThemeColorExpanded } from '../../types'
import { Lot, LotType, lotTypeValues } from '../../types/types'

const now = new Date()

const year = now.getFullYear()
const month = (now.getMonth() + 1).toString().padStart(2, '0')
const day = now.getDate().toString().padStart(2, '0')

const formattedDate = `${year}-${month}-${day}`

type LotCreateViewProps = {
  tmpId?: string
  editLotId?: number
  onCreate?: (id: string) => void
  onSave?: () => void
}

const buttonAnimationValue = new Animated.Value(0)

const LotCreateView: React.FC<LotCreateViewProps> = ({ tmpId, editLotId, onCreate, onSave }) => {
  const { i18n } = useLanguage()
  const { lot, setLot, setIsFormValid, isFormValid } = useLotCreation()
  const { getLotById, addLot, updateLot } = useLots()
  const { standingUp, currentOrganization } = useData()
  const { alert } = useAlert()
  const { lotSettings } = useSettings()
  const [localLotSettings] = useState<LotSettings>(lotSettings)
  const { theme } = useTheme<ThemeColorExpanded>()

  useEffect(() => {
    if (editLotId) {
      const fetchPackage = async () => {
        try {
          const existingLot = await api.getLotFromId({ lotId: editLotId })
          setLot({
            ...existingLot,
            lotType: mapLotTypeFromString(existingLot.lotType),
          })
        } catch (error) {
          console.error('Failed to fetch package:', error)
        }
      }

      fetchPackage().catch(console.error)
    } else if (tmpId) {
      fetchLotById(tmpId)
    } else {
      const newLot: Partial<Lot> = {
        name: '',
        lotType: undefined,
        customerId: undefined,
        woodId: undefined,
        sawmillId: undefined,
        measurerId: undefined,
        notes: '',
        date: formattedDate,
        commission: 0,
        settings: localLotSettings,
      }
      setLot(newLot)
    }
  }, [tmpId, setLot])

  useEffect(() => {
    const fetchLastLotData = async () => {
      if (!tmpId && Platform.OS !== 'web') {
        const lastLotDataString = await AsyncStorage.getItem(STORAGE_KEYS.LAST_LOT_DATA)
        if (lastLotDataString) {
          const savedLot: Partial<Lot> = JSON.parse(lastLotDataString) as Partial<Lot>
          if (lotSettings.enableDoubleDiameter) {
            savedLot.lotType = { value: 'Diameter1', label: 'DIAMETER_1' }
          }
          setLot({ ...lot, ...savedLot })
        }
      }
    }

    fetchLastLotData().catch(console.error)
  }, [])

  function fetchLotById(id: string) {
    try {
      const existingLot = getLotById(id) as Lot
      setLot(existingLot)
    } catch (error) {
      console.error('Failed to fetch lot:', error)
    }
  }

  function handleChange(value: Partial<Lot>) {
    setLot(prev => ({ ...prev, ...value }))
    // const updatedLot = { ...lot, ...value }
    // setLot(updatedLot)
    // validateForm(updatedLot)
  }

  function validateForm(lot2: Partial<Lot>) {
    const requiredFields: { name: string; value: any }[] = [
      { name: 'name', value: lot2.name ?? lot2.lotName },
      { name: 'lotType', value: lot2.lotType },
      { name: 'customerId', value: lot2.customerId },
      { name: 'woodId', value: lot2.woodId },
      { name: 'sawmillId', value: lot2.sawmillId },
      { name: 'measurerId', value: lot2.measurerId },
    ]

    const missingFields = requiredFields.filter(field => field.value === undefined || field.value === '').map(field => field.name)

    let isValid = missingFields.length === 0

    if (lot2.lotType?.value === 'StandingUp') {
      const isWoodInStandingUp = standingUp.find(s => s.woodId === lot2.woodId)
      if (!isWoodInStandingUp) {
        isValid = false
        missingFields.push('woodId (valid for StandingUp)')
      }
    }

    if (!isValid) {
      console.log('Missing fields:', missingFields.join(', '))
    }

    setIsFormValid(isValid)
  }

  useEffect(() => {
    validateForm(lot)
  }, [lot, validateForm])

  const handlePress = () => {
    if (onCreate) handleCreate().catch(console.error)
    if (onSave) handleSave().catch(console.error)
  }

  function mapLotTypeFromString(typeString: string | LotType): LotType | undefined {
    return lotTypeValues.find(type => type.value === typeString)
  }

  const handleSave = async () => {
    if (Platform.OS !== 'web') return
    if (!isFormValid) return
    if (!currentOrganization) return

    if (editLotId) {
      const updatedLot: PutLotRequest = {
        lotId: editLotId ?? 0,
        lotNumber: lot.name ?? lot.lotName ?? '',
        lotCommission: lot.commission ?? 0,
        lotName: lot.lotName ?? lot.name ?? '',
        lotType: lot.lotType?.value ?? '',
        customerId: lot.customerId ?? 0,
        measurerId: lot.measurerId ?? 0,
        organizationId: lot.organizationId ?? 0,
        originId: lot.originId ?? 0,
        sawmillId: lot.sawmillId ?? 0,
        woodId: lot.woodId ?? 0,
        addDate: lot.addDate ?? new Date().toISOString(),
        editDate: new Date().toISOString(),
        note: lot.notes || '',
        validated: lot.validated ?? false,
        validatedBy: lot.validatedBy ?? null,
        trunks: lot.trunks || [],
        background: undefined,
        flgClosed: lot.closed ?? false,
        flgDeleted: false,
      }
      await api.putLot(updatedLot).then(() => {
        if (onSave) onSave()
      })
    } else {
      const updatedLot: PostLotRequest = {
        lotNumber: lot.name ?? lot.lotName ?? '',
        lotName: lot.name ?? lot.lotName ?? '',
        addDate: lot.date ?? lot.addDate ?? '',
        organizationId: lot.organizationId ?? currentOrganization.organizationId,
        customerId: lot.customerId ?? -1,
        woodId: lot.woodId ?? -1,
        measurerId: lot.measurerId ?? -1,
        sawmillId: lot.sawmillId ?? -1,
        lotType: (lot.lotType?.value as string) ?? '',
        lotCommission: lot.commission ?? -1,
        originId: lot.originId ?? -1,
        editDate: lot.date ?? '',
        trunks: lot.trunks ?? [],
        flgClosed: lot.closed ?? false,
      }

      await api.postLots(updatedLot).then(() => {
        if (onSave) onSave()
      })
    }
  }

  const handleCreate = async () => {
    if (isFormValid) {
      try {
        const serializedLot = JSON.stringify(lot)
        await AsyncStorage.setItem(STORAGE_KEYS.LAST_LOT_DATA, serializedLot)
      } catch (error) {
        console.error('Error saving lot data:', error)
      }

      const updatedLot: Lot = {
        ...lot,
        tmpId: tmpId ? tmpId : uuid.v4(),
        closed: false,
        trunks: [],
        settings: lot.settings ?? lotSettings,
      } as Lot

      setLot(updatedLot)
      if (!tmpId) {
        addLot(updatedLot)
      } else {
        updateLot(updatedLot)
      }
      if (onCreate) onCreate(updatedLot.tmpId)
    } else {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
    }
  }

  return (
    <>
      <ScrollViewForm>
        <DateInput
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('DATE')}
          value={lot.date || formattedDate}
          editable={false}
          onChangeDate={() => handleChange({ date: formattedDate })}
          spacing={['all']}
        />
        <NumberInput
          title={i18n.t('COMMISSION')}
          placeholder={i18n.t('COMMISSION')}
          value={lot.commission || 0}
          disableFastInputs
          onEndEditing={value => handleChange({ commission: Number(value.nativeEvent.text) })}
          spacing={['all']}
        />
        <TextInput
          required
          title={i18n.t('NAME')}
          placeholder={i18n.t('NAME')}
          value={lot.name || lot.lotName || ''}
          onChangeText={value => handleChange({ name: value })}
          spacing={'all'}
        />

        <TextInput
          title={i18n.t('NOTES')}
          placeholder={i18n.t('NOTES')}
          value={lot.notes || ''}
          onChangeText={value => handleChange({ notes: value })}
          spacing={'all'}
        />
        {!lotSettings.enableDoubleDiameter && (
          <LotTypeSelector
            editable={true}
            required
            selectedLotType={lot.lotType}
            onChange={value => handleChange({ lotType: value })}
            spacing={'all'}
          />
        )}
        <CustomerSelector
          required
          selectedCustomerId={lot.customerId}
          editable={true}
          onChange={value => handleChange({ customerId: value?.customerId })}
          spacing={['all']}
        />
        <WoodSelector
          required
          selectedWoodId={lot.woodId}
          editable={true}
          onChange={value => handleChange({ woodId: value?.woodId })}
          spacing={['all']}
          showReduction
        />
        {Platform.OS !== 'web' && (
          <QualitySelector
            required
            selectedQualityId={lot.qualityId}
            editable={true}
            onChange={value => handleChange({ qualityId: value?.qualityId })}
            spacing={['all']}
          />
        )}
        <OriginSelector
          required
          selectedOriginId={lot.originId}
          editable={true}
          onChange={value => handleChange({ originId: value?.originId })}
          spacing={['all']}
        />
        <SawmillSelector
          required
          selectedSawmillId={lot.sawmillId}
          editable={true}
          onChange={value => handleChange({ sawmillId: value?.sawmillId })}
          spacing={['all']}
        />
        <MeasurerSelector
          required
          selectedMeasurerId={lot.measurerId}
          editable={true}
          onChange={value => handleChange({ measurerId: value?.measurerId })}
          spacing={['all']}
        />
        {currentOrganization?.organizationId === 303 && (
          <>
            <IM.Text>{i18n.t('VALIDATED')}</IM.Text>
            <Switch onValueChange={() => handleChange({ validated: !lot.validated })} value={lot.validated} />
            <TextInput
              title={i18n.t('VALIDATED_BY')}
              value={lot.validatedBy ?? ''}
              onChangeText={value => handleChange({ validatedBy: value })}
              editable={true}
              spacing={['all']}
            />
          </>
        )}
      </ScrollViewForm>
      {Platform.OS === 'web' ? (
        <IM.View style={styles.buttonContainer}>
          <PressableTextIcon
            icon={['fal', 'floppy-disk']}
            alignIcon="right"
            pressableStyle={styles.webButton}
            style={{ color: theme.button.icon }}
            iconColor={theme.button.icon}
            onPress={() => handlePress()}>
            {i18n.t('SAVE')}
          </PressableTextIcon>
        </IM.View>
      ) : (
        <AnimatedButton
          style={styles.button}
          id={ADD_PACKAGE_BUTTON_ID}
          value={buttonAnimationValue}
          icon={['fal', 'check']}
          iconSize={50}
          onPress={() => handlePress()}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
})

export default LotCreateView
