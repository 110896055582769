import { IM, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import NumberInput from '../components/NumberInput'
import { usePackage } from '../hooks/usePackage'
import { ModalScreenRef, ThemeColorExpanded } from '../types'
import { Board } from '../types/types'
import BaseServiceModal from './BaseServiceModal'

export type EditBoardModalProps = {
  controller: ModalController<EditBoardModalData>
}

export type EditBoardModalData = {
  packageId: string
  board: Board
}

export default function EditBoardModal({ controller }: EditBoardModalProps) {
  const ref = createRef<ModalScreenRef>()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()

  const { pkg, setPackage } = usePackage(controller.data?.packageId || '')

  const [isEditMode, setIsEditMode] = useState(false)
  const [isDeleteMode, setIsDeleteMode] = useState(false)

  const [thickness, setThickness] = useState(0)
  const [length, setLength] = useState(0)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (!controller.data?.board) return
    setThickness(controller.data.board.boardHeight)
    setLength(controller.data.board.boardLenght)
    setWidth(controller.data.board.boardWidth)
  }, [controller.data?.board])

  const handleCloseModal = () => {
    closeModal()
  }

  const onEdit = () => {
    setIsEditMode(true)
  }

  const onDelete = () => {
    setIsDeleteMode(true)
  }

  const closeModal = () => {
    setIsEditMode(false)
    setIsDeleteMode(false)
    controller.close()
  }

  const onSaveBoard = () => {
    const currentBoard = pkg?.boards.find(board => board.boardNumber === controller.data?.board.boardNumber)
    if (!currentBoard || !pkg) return
    currentBoard.boardHeight = thickness
    currentBoard.boardLenght = length
    currentBoard.boardWidth = width

    setPackage({
      ...pkg,
      boards: [...pkg.boards],
    })
    closeModal()
  }

  const onDeleteConfirm = () => {
    if (!pkg) return

    const updatedBoards = pkg.boards.filter(board => board.boardNumber !== controller.data?.board.boardNumber)

    setPackage({
      ...pkg,
      boards: updatedBoards,
    })
    closeModal()
  }

  const onReorderDelete = () => {
    if (!pkg) return
    const updatedBoards = pkg.boards.filter(board => board.boardNumber !== controller.data?.board.boardNumber)
    const reorderedBoards = updatedBoards.map((board, index) => ({
      ...board,
      boardNumber: index + 1,
      orderItemId: index + 1,
    }))

    setPackage({
      ...pkg,
      boards: reorderedBoards,
    })
    setIsDeleteMode(false)
    controller.close()
  }

  return (
    <BaseServiceModal controller={controller} childRef={ref} style={styles.customModalStyle} onModalShow={() => console.log('onModalShow')}>
      <IM.View style={styles.container}>
        <TouchableOpacity style={styles.closeButton} onPress={handleCloseModal}>
          <IM.Icon icon={['fal', 'times']} size={22} color={theme.general.text} />
        </TouchableOpacity>
        {!isEditMode && !isDeleteMode && (
          <IM.View style={styles.buttonContainer}>
            <IM.Text style={styles.buttonLabel}> {`${i18n.t('BOARD_N')} ${controller.data?.board.boardNumber ?? ''}`}</IM.Text>
            <IM.Button title={i18n.t('EDIT')} style={styles.fullWidthButton} onPress={onEdit} />
            <IM.Button title={i18n.t('DELETE')} style={styles.fullWidthButton} onPress={onDelete} />
          </IM.View>
        )}

        {isEditMode && (
          <IM.View style={styles.inputContainer}>
            <IM.Text style={styles.label}>{i18n.t('THICKNESS')}</IM.Text>
            <NumberInput
              disableFastInputs
              value={thickness}
              onChange={value => setThickness(Number(value.nativeEvent.text))}
              placeholder={i18n.t('THICKNESS')}
              style={styles.input}
            />
            <IM.Text style={styles.label}>{i18n.t('LENGTH')}</IM.Text>

            <NumberInput
              disableFastInputs
              value={length}
              onChange={value => setLength(Number(value.nativeEvent.text))}
              placeholder={i18n.t('LENGTH')}
              style={styles.input}
            />
            <IM.Text style={styles.label}>{i18n.t('WIDTH')}</IM.Text>

            <NumberInput
              disableFastInputs
              value={width}
              onChange={value => setWidth(Number(value.nativeEvent.text))}
              placeholder={i18n.t('WIDTH')}
              style={styles.input}
            />
            <IM.Button title={i18n.t('SAVE')} style={styles.fullWidthButton} onPress={onSaveBoard} />
          </IM.View>
        )}

        {isDeleteMode && (
          <IM.View style={styles.deleteContainer}>
            <IM.Text>{i18n.t('BOARDS_REORDER')}</IM.Text>
            <IM.View style={styles.buttonRow}>
              <IM.Button title={i18n.t('YES')} style={styles.halfWidthButton} onPress={onReorderDelete} />
              <IM.Button title={i18n.t('NO')} style={styles.halfWidthButton} onPress={onDeleteConfirm} />
            </IM.View>
          </IM.View>
        )}
      </IM.View>
    </BaseServiceModal>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    flex: 1,
    justifyContent: 'center',
  },
  inputContainer: {
    flex: 1,
    marginTop: 20,
  },
  deleteContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
  },
  fullWidthButton: {
    marginVertical: 10,
  },
  input: {
    marginVertical: 10,
  },
  customModalStyle: {
    width: '80%',
    height: '70%',
    backgroundColor: '#e5dddd',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: 10,
  },
  label: {
    fontSize: 14,
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
  },
  halfWidthButton: {
    flex: 1,
    marginHorizontal: 5,
    marginVertical: 10,
  },
  buttonLabel: {
    fontSize: 16,
    marginBottom: 10,
  },
})
