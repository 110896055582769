import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import { useAuthentication } from '../../contexts/AuthenticationContext'
import { useData } from '../../contexts/DataContext'
import { ThemeColorExpanded } from '../../types'
import { DisplayStatistics } from '../../types/types'

export default function StatisticsView({
  setIsLoading,
  isLoading,
}: {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
}) {
  const { colorScheme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()

  const { isAdmin } = useAuthentication()
  const { packagesStatistics, lotsStatistics, loadStatistics, statisticsLoaded } = useData()

  useEffect(() => {
    if (statisticsLoaded) return
    loadData()
  }, [])

  function loadData() {
    setIsLoading(true)
    loadStatistics(isAdmin)
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  const tableHeader: TableHeaderData<DisplayStatistics>[] = useMemo(
    () => [
      { title: i18n.t('ORGANIZATION'), property: 'organizationTitle' },
      { title: `${i18n.t('VOLUME')} (m³)`, property: 'volume' },
    ],
    [i18n]
  )

  const packagesStatisitcsData: DisplayStatistics[] = useMemo(
    () =>
      packagesStatistics.map(stat => ({
        organizationTitle: `${stat.organizationId} - ${stat.organizationName}`,
        volume: stat.volume,
      })),
    [packagesStatistics]
  )

  const lotsStatisticsData: DisplayStatistics[] = useMemo(
    () =>
      lotsStatistics.map(stat => ({
        organizationTitle: `${stat.organizationId} - ${stat.organizationName}`,
        volume: stat.volume,
      })),
    [lotsStatistics]
  )

  if (!isAdmin) return <></>

  return (
    <>
      {isLoading && (
        <IM.View spacing={'all'} style={styles.loadingContainer}>
          <IM.LoadingSpinner isVisible={isLoading} />
        </IM.View>
      )}
      {!isLoading && (
        <>
          <ScrollView indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}>
            <IM.View spacing="all" style={styles.container}>
              <IM.View spacing={'all'} style={IMLayout.flex.row}>
                <IM.View spacing={'right'} style={IMLayout.flex.f1}>
                  <TableWrapper
                    tableTitle={i18n.t('PACKAGES_VOLUMES')}
                    tableContainerSpacing={'all'}
                    headerData={tableHeader}
                    tableData={packagesStatisitcsData}
                    maxTableHeight={730}
                    flexArray={[2, 1]}
                  />
                </IM.View>
                <IM.View spacing={'left'} style={IMLayout.flex.f1}>
                  <TableWrapper
                    tableTitle={i18n.t('LOTS_VOLUMES')}
                    tableContainerSpacing={'all'}
                    headerData={tableHeader}
                    tableData={lotsStatisticsData}
                    maxTableHeight={730}
                    flexArray={[2, 1]}
                  />
                </IM.View>
              </IM.View>
            </IM.View>
          </ScrollView>
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
  loadingContainer: { flex: 1, justifyContent: 'center', alignItems: 'center' },
})
