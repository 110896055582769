import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'

import { Screen } from '../../components/screen/Screen'
import { ThemeColorExpanded } from '../../types'
import BaseHeader from '../BaseHeader'
import WoodTypesView from './WoodTypesView'

export default function AttributesScreen() {
  const { i18n } = useLanguage()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Screen>
      <Screen.Header>
        <BaseHeader title={i18n.t('WOOD_TYPES')} />
      </Screen.Header>
      <Screen.Content>
        <WoodTypesView setIsLoading={setIsLoading} isLoading={isLoading} />
      </Screen.Content>
    </Screen>
  )
}
