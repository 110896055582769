import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { PostReductionTypeFormulaRequest, PostReductionTypeRequest } from '../../apis/apiRequestTypes'
import AnimatedButton from '../../components/AnimatedButton'
import NumberInput from '../../components/NumberInput'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import WoodTypeSelector from '../../components/selectors/WoodTypeSelector'
import TextInput from '../../components/TextInput'
import { CREATE_WOOD_TYPE_BUTTON_ID } from '../../constants/ButtonIds'
import { ThemeColorExpanded, UploadStatus } from '../../types'
import { ReductionType, ReductionTypeFormula } from '../../types/types'

const buttonAnimationValue = new Animated.Value(0)

export interface ReductionTypeCreateViewProps {
  editId?: string
  onCreateReductionType?: (reductionType: ReductionType | ReductionTypeFormula | unknown) => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
}

type ValidateReductionTypeField = 'reductionTypeName' | 'reductionTypeCode' | 'reductionTypeFormulaCode' | 'woodTypeId'
type RequiredField = { value: string | undefined; field: ValidateReductionTypeField }

const ReductionTypeCreateView: React.FC<ReductionTypeCreateViewProps & Props> = ({ editId, onCreateReductionType, onUploadStatus }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const [reductionType, setReductionType] = useState<Partial<PostReductionTypeRequest>>({})
  const [reductionTypeFormula, setReductionTypeFormula] = useState<Partial<PostReductionTypeFormulaRequest>>({})
  const [missingFields, setMissingFields] = useState<ValidateReductionTypeField[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<Partial<PostReductionTypeRequest>>({})

  useEffect(() => {
    if (editId) {
      loadEditReductionType().catch(console.error)
    }
  }, [editId])

  async function loadEditReductionType() {
    setIsLoading(true)
    await api
      .getReductionTypeFormula({ reductionTypeId: Number(editId) })
      .then(response => {
        const editReductionTypeFormula = {
          reductionTypeFormulaCode: response.reductionTypeFormulaCode,
          reductionPercentage: response.reductionPercentage,
          reductionStartCm: response.reductionStartCm,
          reductionEndCm: response.reductionEndCm,
          woodTypeId: response.woodTypeId,
          reductionTypeId: response.reductionTypeId,
          reductionTypeFormulaId: response.reductionTypeFormulaId,
        }
        setReductionTypeFormula(editReductionTypeFormula)
        setInitialValue(editReductionTypeFormula)
      })
      .catch(console.error)
      .then(() => setIsLoading(false))
  }

  function handleChange(value: Partial<PostReductionTypeRequest>) {
    setReductionType(prev => ({ ...prev, ...value }))
  }

  function handleChangeFormula(value: Partial<PostReductionTypeFormulaRequest>) {
    setReductionTypeFormula(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    let requiredFields: RequiredField[] = []
    if (!editId) {
      requiredFields = [
        { value: reductionType.reductionTypeName, field: 'reductionTypeName' },
        { value: reductionType.reductionTypeCode, field: 'reductionTypeCode' },
      ]
    } else {
      requiredFields = [{ value: reductionTypeFormula.reductionTypeFormulaCode, field: 'reductionTypeFormulaCode' }]
    }
    const notFilledFields = requiredFields.filter(field => field.value === undefined || field.value === '').map(field => field.field)
    setMissingFields(notFilledFields)
    if (notFilledFields.length > 0) {
      onUploadStatus('mandatoryMissing')
    }
    return notFilledFields.length === 0
  }

  function haveValuesChanged() {
    return JSON.stringify(reductionType) !== JSON.stringify(initialValue)
  }

  useEffect(() => {
    if (haveValuesChanged()) {
      onUploadStatus('waiting')
    }
    if (!showMissingFields) return
    if (!validateForm()) return
  }, [reductionType, showMissingFields])

  const handlePress = () => {
    setShowMissingFields(true)
    setIsLoading(true)
    onUploadStatus('uploading')
    if (!editId) {
      handleCreate()
        .catch(console.error)
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      handleUpdate()
        .catch(console.error)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleUpdate = async () => {
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }

    const reductionTypeFormulaBody: Partial<ReductionTypeFormula> = {
      reductionTypeId: Number(editId) ?? 0,
      reductionPercentage: reductionTypeFormula.reductionPercentage ?? undefined,
      reductionStartCm: reductionTypeFormula.reductionStartCm ?? 0,
      reductionEndCm: reductionTypeFormula.reductionEndCm ?? 0,
      woodTypeId: reductionTypeFormula.woodTypeId ?? 0,
      reductionTypeFormulaCode: reductionTypeFormula.reductionTypeFormulaCode ?? '',
    }

    if (reductionTypeFormula.reductionTypeFormulaId) {
      reductionTypeFormulaBody.reductionTypeFormulaId = reductionTypeFormula.reductionTypeFormulaId
    }

    if (reductionTypeFormulaBody.reductionTypeFormulaId && reductionTypeFormulaBody.reductionTypeFormulaId !== 0) {
      await api
        .putReductionTypeFormula(reductionTypeFormulaBody as ReductionTypeFormula)
        .then(response => {
          //TODO
          onCreateReductionType?.(response)
          onUploadStatus('done')
        })
        .catch(error => {
          console.log(error)
          alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
    } else {
      await api
        .postReductionTypeFormula(reductionTypeFormulaBody as ReductionTypeFormula)
        .then(response => {
          //TODO
          onCreateReductionType?.(response)
          onUploadStatus('done')
        })
        .catch(error => {
          console.log(error)
          alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
    }
  }

  const handleCreate = async () => {
    //VALIDATION
    // check if all required fields are filled
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }

    const reductionTypeBody: ReductionType = {
      reductionTypeId: reductionType.reductionTypeId ?? 0,
      reductionTypeCode: reductionType.reductionTypeCode ?? '',
      reductionTypeName: reductionType.reductionTypeName ?? '',
    }

    //TODO: check return type of putReductionType
    //UPDATE reductionType
    //   await api
    //     .putReductionType(reductionTypeBody)
    //     .then(response => {
    //       console.log(response)
    //       onUploadStatus('done')
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
    //         {
    //           text: i18n.t('OK'),
    //           style: 'default',
    //           onPress: () => {
    //             return
    //           },
    //         },
    //       ])
    //     })
    //   return

    //CREATE reductionType
    await api
      .postReductionType(reductionTypeBody)
      .then(response => {
        //TODO
        onCreateReductionType?.(response)
        onUploadStatus('done')
      })
      .catch(error => {
        console.log(error)
        alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
          {
            text: i18n.t('OK'),
            style: 'default',
            onPress: () => {
              return
            },
          },
        ])
      })
  }

  return (
    <>
      {!isLoading && (
        <>
          {!editId ? (
            <ScrollViewForm>
              <TextInput
                required
                title={i18n.t('NAME')}
                placeholder={i18n.t('NAME')}
                value={reductionType.reductionTypeName ?? ''}
                onChangeText={value => handleChange({ reductionTypeName: value })}
                spacing={'all'}
                error={missingFields.includes('reductionTypeName')}
              />
              <TextInput
                required
                title={i18n.t('CODE')}
                placeholder={i18n.t('CODE')}
                value={reductionType.reductionTypeCode ?? ''}
                onChangeText={value => handleChange({ reductionTypeCode: value })}
                spacing={'all'}
                error={missingFields.includes('reductionTypeCode')}
              />
            </ScrollViewForm>
          ) : (
            <ScrollViewForm>
              <TextInput
                required
                title={i18n.t('CODE')}
                placeholder={i18n.t('CODE')}
                value={reductionTypeFormula.reductionTypeFormulaCode ?? ''}
                onChangeText={value => handleChangeFormula({ reductionTypeFormulaCode: value })}
                spacing={'all'}
                error={missingFields.includes('reductionTypeFormulaCode')}
              />
              <WoodTypeSelector
                required
                selectedWoodTypeId={reductionTypeFormula.woodTypeId}
                editable={true}
                onChange={value => handleChangeFormula({ woodTypeId: value?.woodTypeId })}
                spacing={['all']}
              />
              <TextInput
                title={i18n.t('REDUCTION_PERCENTAGE')}
                placeholder={i18n.t('REDUCTION_PERCENTAGE')}
                value={reductionTypeFormula.reductionPercentage?.toString() ?? ''}
                keyboardType="numeric"
                onChangeText={value => handleChangeFormula({ reductionPercentage: value || undefined })}
                spacing={'all'}
              />
              <NumberInput
                title={i18n.t('REDUCTION_START_CM')}
                placeholder={i18n.t('REDUCTION_START_CM')}
                value={reductionTypeFormula.reductionStartCm?.toString() ?? ''}
                keyboardType="numeric"
                onChange={value => handleChangeFormula({ reductionStartCm: Number(value.nativeEvent.text) })}
                spacing={'all'}
                disableFastInputs
              />
              <NumberInput
                title={i18n.t('REDUCTION_END_CM')}
                placeholder={i18n.t('REDUCTION_END_CM')}
                value={reductionTypeFormula.reductionEndCm?.toString() ?? ''}
                keyboardType="numeric"
                onChange={value => handleChangeFormula({ reductionEndCm: Number(value.nativeEvent.text) })}
                spacing={'all'}
                disableFastInputs
              />
            </ScrollViewForm>
          )}

          {Platform.OS === 'web' ? (
            <IM.View style={styles.buttonContainer}>
              <PressableTextIcon
                icon={['fal', 'floppy-disk']}
                alignIcon="right"
                pressableStyle={styles.webButton}
                style={{ color: theme.button.icon }}
                iconColor={theme.button.icon}
                onPress={() => handlePress()}>
                {i18n.t('SAVE')}
              </PressableTextIcon>
            </IM.View>
          ) : (
            <AnimatedButton
              style={styles.button}
              id={CREATE_WOOD_TYPE_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'check']}
              iconSize={50}
              onPress={() => handlePress()}
            />
          )}
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default ReductionTypeCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
