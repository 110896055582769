import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import WoodTypeCreateScreen from '../screens/WoodTypeCreate/WoodTypeCreateScreen'
import { WoodTypeCreateViewProps } from '../screens/WoodTypeCreate/WoodTypeCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type WoodTypeCreateModalProps = {
  controller: ModalController<WoodTypeCreateViewProps>
} & Pick<WoodTypeCreateViewProps, 'onCreateWoodType'>

export default function WoodTypeCreateModal({ controller, onCreateWoodType }: WoodTypeCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <WoodTypeCreateScreen ref={ref} onCreateWoodType={onCreateWoodType} controller={controller} />
    </BaseServiceModal>
  )
}
