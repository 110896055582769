import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { OptionsProvider } from '@infominds/react-native-camera'
import { useDimensions, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { Platform, StatusBar } from 'react-native'
import Orientation from 'react-native-orientation-locker'

import WebConfig from '../app.config'
import AppContent from '../components/Infominds/AppContent'
import { TabBarProvider } from '../components/Infominds/DrawerNavigator'
import CONSTANTS from '../constants/constants'
import { useAuthentication } from '../contexts/AuthenticationContext'
import { MenuProvider } from '../contexts/MenuContext'
import navigationUtils from '../utils/navigationUtils'
import { BottomTabs, LoginStackNavigator, RootStackNavigator } from './NavigationConfig'
import NavigationContainer from './NavigationContainer'
import { BottomTabParamList, TabNavigationScreen } from './types'

interface Props {
  isLoggedIn: boolean
  basePath: string
}
const basePath = WebConfig.BASE_PATH.includes('/') ? WebConfig.BASE_PATH : '/'
export default function Navigator({ isLoggedIn }: Props) {
  const { colorScheme } = useTheme()
  const { isSmallDevice } = useDimensions()
  const { isAdmin } = useAuthentication()

  useEffect(() => {
    isSmallDevice && Orientation.lockToPortrait()
  }, [isSmallDevice])

  const elements = isSmallDevice ? CONSTANTS.maxNavBarElements : CONSTANTS.maxTabletNavBarElements
  const bottomTabs = useMemo(() => {
    const tabs: TabNavigationScreen<keyof BottomTabParamList>[] = []

    if (Platform.OS === 'web') {
      if (isAdmin) {
        tabs.push(BottomTabs.allOrganizations)
        tabs.push(BottomTabs.statistics)
        tabs.push(BottomTabs.woodTypes)
        tabs.push(BottomTabs.reductionFormulas)
      }
      tabs.push(BottomTabs.organization)
      tabs.push(BottomTabs.attributes)
      tabs.push(BottomTabs.lots)
      tabs.push(BottomTabs.packages)
    } else {
      tabs.push(BottomTabs.lots)
      tabs.push(BottomTabs.packages)
    }

    tabs.push(BottomTabs.settings)

    return navigationUtils.sortTabs(tabs, elements)
  }, [isAdmin, isSmallDevice, elements])

  return (
    <OptionsProvider>
      <MenuProvider bottomTabs={bottomTabs} maxDynamicElements={elements}>
        <StatusBar backgroundColor="transparent" barStyle={colorScheme === 'dark' ? 'light-content' : 'dark-content'} translucent />
        <AppContent>
          <BottomSheetModalProvider>
            <NavigationContainer basePath={basePath}>
              <>
                {!isLoggedIn && <LoginStackNavigator />}
                {isLoggedIn && (
                  <TabBarProvider>
                    <RootStackNavigator />
                  </TabBarProvider>
                )}
              </>
            </NavigationContainer>
          </BottomSheetModalProvider>
        </AppContent>
      </MenuProvider>
    </OptionsProvider>
  )
}
