import { IM, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import DateInput from '../../components/DateInput'
import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import { useData } from '../../contexts/DataContext'
import useDeleteAlert from '../../hooks/useDeleteAlert'
import LotCreateModal, { LotCreateModalControllerData } from '../../modals/LotCreateModal'
import TrunkCreateModal, { TrunkCreateModalControllerData } from '../../modals/TrunkCreateModal'
import { ThemeColorExpanded } from '../../types'
import { Lot, Trunk } from '../../types/types'

export interface LotsTableData {
  lotId: number
  lotName: string
  woodName: string
  qualityName: string
  customerName: string
  measurerName: string
  sawmillName: string
  originName: string
  addDate: string
  trunks: TrunkTableData[]
  grossVolume?: number
  netVolume?: number
  totalTrunks: number
}

export interface TrunkTableData {
  trunkId: number
  lotId: number
  trunkNumber: number
  woodName: string
  qualityName: string
  addDate: string
  lenght: number
  diameter1: number
  diameter2: number
  diameter3: number
  diameter4: number
  reduction: number
  latitude?: number
  longitude?: number
  netVolume?: number
  grossVolume?: number
}

export default function LotsView() {
  const { colorScheme } = useTheme<ThemeColorExpanded>()
  const { i18n, language } = useLanguage()

  const [busy, setBusy] = useState(false)

  const [lots, setLots] = useState<Lot[]>([])
  const [fromDate, setFromDate] = useState<Date>(() => {
    const date = new Date()
    date.setDate(date.getDate() - 7)
    return date
  })
  const [toDate, setToDate] = useState<Date>(new Date())
  const deleteAlert = useDeleteAlert()
  const { alert } = useAlert()
  const { currentOrganization, woods, qualities } = useData()
  const createLotModal = useModalController<LotCreateModalControllerData>()
  const createTrunkModal = useModalController<TrunkCreateModalControllerData>()

  const lotsTableHeader: TableHeaderData<LotsTableData>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'lotName' },
      { title: i18n.t('DATE'), property: 'addDate' },
      { title: i18n.t('CUSTOMER'), property: 'customerName' },
      { title: i18n.t('WOOD'), property: 'woodName' },
      { title: i18n.t('QUALITY'), property: 'qualityName' },
      { title: i18n.t('ORIGIN'), property: 'originName' },
      { title: i18n.t('MEASURER'), property: 'measurerName' },
      { title: i18n.t('SAWMILL'), property: 'sawmillName' },
      { title: i18n.t('VOLUME_S'), property: 'netVolume' },
      { title: i18n.t('VOLUME_S'), property: 'grossVolume' },
      { title: i18n.t('TRUNKS'), property: 'totalTrunks' },
    ],
    [i18n]
  )

  const trunksTableHeader: TableHeaderData<TrunkTableData>[] = useMemo(
    () => [
      { title: i18n.t('TRUNK_N'), property: 'trunkNumber' },
      { title: i18n.t('QUALITY'), property: 'qualityName' },
      { title: i18n.t('WOOD'), property: 'woodName' },
      { title: i18n.t('REDUCTION'), property: 'reduction' },
      { title: i18n.t('LENGTH'), property: 'lenght' },
      { title: i18n.t('DIAMETER_1'), property: 'diameter1' },
      { title: i18n.t('DIAMETER_2'), property: 'diameter2' },
      { title: i18n.t('DIAMETER_3'), property: 'diameter3' },
      { title: i18n.t('DIAMETER_4'), property: 'diameter4' },
      { title: i18n.t('VOLUME_S'), property: 'netVolume' },
      { title: i18n.t('VOLUME_S'), property: 'grossVolume' },
    ],
    [i18n]
  )

  useEffect(() => {
    fetchLots()
  }, [])

  useEffect(() => {
    fetchLots()
  }, [fromDate, toDate])

  const lotsTableData: LotsTableData[] = useMemo(() => {
    return lots.map(lot => {
      const mappedTrunks = lot.trunks.map(trunk => ({
        trunkId: trunk.trunkId ?? 0,
        lotId: lot.lotId,
        addDate: trunk.addDate
          ? new Date(trunk.addDate).toLocaleDateString('it-IT', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })
          : '',
        trunkNumber: trunk.trunkNumber,
        qualityName: qualities.find(q => q.qualityId === trunk.qualityId)?.qualityName ?? '',
        woodName: woods.find(w => w.woodId === trunk.woodId)?.woodName ?? '',
        reduction: trunk.reduction ?? 0,
        lenght: trunk.lenght ?? 0,
        diameter1: trunk.diameter1 ?? 0,
        diameter2: trunk.diameter2 ?? 0,
        diameter3: trunk.diameter3 ?? 0,
        diameter4: trunk.diameter4 ?? 0,
        grossVolume: trunk.grossVolume ?? 0,
        netVolume: trunk.netVolume ?? 0,
      }))

      return {
        lotId: lot.lotId,
        lotName: lot.lotName ?? '',
        woodName: lot.wood?.woodName ?? '',
        qualityName: lot.quality?.qualityName ?? '',
        customerName: lot.customer?.customerName ?? '',
        measurerName: lot.measurer?.measurerName ?? '',
        sawmillName: lot.sawmill?.sawmillName ?? '',
        originName: lot.origin?.originName ?? '',
        addDate: lot.addDate
          ? new Date(lot.addDate).toLocaleDateString('it-IT', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })
          : '',
        trunks: mappedTrunks,
        validated: lot.validated ?? false,
        validatedBy: lot.validatedBy ?? '',
        netVolume: lot.netVolume,
        grossVolume: lot.grossVolume,
        totalTrunks: lot.trunks.length,
      }
    })
  }, [lots, qualities, woods, language])

  function fetchLots() {
    setBusy(true)
    api
      .getLotsFromDates({ startDate: fromDate, endDate: toDate })
      .then(response => {
        const processedLots = response.map(lot => {
          const trunksWithVolumes = lot.trunks.map(trunk => {
            const { diameter1, diameter2, diameter3, diameter4, lenght, reduction } = trunk

            const lengthInMeters = lenght / 100
            const diametersInMeters = [diameter1, diameter2, diameter3, diameter4].map(d => d / 100)

            const validDiameters = diametersInMeters.filter(d => d > 0)

            const averageDiameter = validDiameters.length > 0 ? validDiameters.reduce((sum, d) => sum + d, 0) / validDiameters.length : undefined

            if (averageDiameter && lengthInMeters) {
              const radius = averageDiameter / 2
              const grossVolume = Math.PI * Math.pow(radius, 2) * lengthInMeters
              const netVolume = grossVolume * (1 - (reduction || 0) / 100)

              return {
                ...trunk,
                grossVolume: parseFloat(grossVolume.toFixed(3)),
                netVolume: parseFloat(netVolume.toFixed(3)),
              }
            }

            return {
              ...trunk,
              grossVolume: 0,
              netVolume: 0,
            }
          })

          const totalGrossVolume = trunksWithVolumes.reduce((acc, trunk) => acc + (trunk.grossVolume || 0), 0)
          const totalNetVolume = trunksWithVolumes.reduce((acc, trunk) => acc + (trunk.netVolume || 0), 0)

          return {
            ...lot,
            trunks: trunksWithVolumes,
            grossVolume: parseFloat(totalGrossVolume.toFixed(3)),
            netVolume: parseFloat(totalNetVolume.toFixed(3)),
          }
        })

        setLots(processedLots)
      })
      .catch(console.error)
      .finally(() => setBusy(false))
  }

  function onNewLotCreated() {
    createLotModal.close()
    fetchLots()
  }

  function onNewTrunkCreated() {
    createTrunkModal.close()
    fetchLots()
  }

  function handleNewPackagePress() {
    createLotModal.show({})
  }

  function handlePackageEdit(lot: LotsTableData) {
    const selectedLot = lots.find(l => l.lotId === lot.lotId)
    if (!selectedLot?.lotId) return
    createLotModal.show({ editLotId: selectedLot?.lotId })
  }

  function handlePackageDelete(pkg: LotsTableData) {
    const selectedPackage = lots.find(p => p.lotId === pkg.lotId)
    if (!selectedPackage?.lotId) {
      alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteLot({ lotId: selectedPackage.lotId })
            .then(() => {
              fetchLots()
            })
            .catch(error => {
              console.log(error)
              alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
                {
                  text: i18n.t('OK'),
                  style: 'default',
                  onPress: () => {
                    return
                  },
                },
              ])
            })
        }
      })
      .catch(console.error)
  }

  function handleTrunkEdit(trunk: TrunkTableData) {
    createTrunkModal.show({ editTrunkId: trunk.trunkId })
  }

  function handleTrunkDelete(trunk: TrunkTableData) {
    console.log('handleTrunkDelete', trunk)
    const selectedTrunk = lots.find(l => l.lotId === trunk.lotId)?.trunks.find(t => t.trunkId === trunk.trunkId)
    if (!selectedTrunk?.trunkId) {
      alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          if (!selectedTrunk?.trunkId) return
          api
            .deleteTrunk({ trunkIds: [selectedTrunk.trunkId] })
            .then(() => {
              fetchLots()
            })
            .catch(error => {
              console.log(error)
              alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
                {
                  text: i18n.t('OK'),
                  style: 'default',
                  onPress: () => {
                    return
                  },
                },
              ])
            })
        }
      })
      .catch(console.error)
  }

  function handleTrunkAdd(parentRow: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    createTrunkModal.show({ lotId: parentRow.lotId })
  }

  function handleImport() {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.csv'

    input.onchange = async event => {
      // eslint-disable-next-line, @typescript-eslint/no-unsafe-member-access
      const fileInput = event.target as HTMLInputElement
      const file = fileInput.files?.[0]
      if (!file) {
        return
      }

      setBusy(true)

      if (!currentOrganization?.organizationId) return
      const formData = new FormData()
      formData.append('file', file)

      try {
        await api.postLotsCsv(formData, currentOrganization?.organizationId)
        alert(i18n.t('IMPORT_SUCCESS'))
      } catch (error) {
        console.error('Error during import:', error)
        alert(i18n.t('IMPORT_ERROR'))
      } finally {
        setBusy(false)
      }
    }

    input.click()
  }

  return (
    <>
      {busy && (
        <IM.View style={styles.loadingContainer}>
          <IM.LoadingSpinner isVisible={true} />
        </IM.View>
      )}
      <ScrollView indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}>
        <IM.View spacing="all" style={styles.container}>
          <IM.View style={styles.datePickerRow}>
            <IM.Text>{i18n.t('FROM_DATE')}:</IM.Text>
            <DateInput
              value={fromDate.toDateString()}
              onChangeDate={date => date && setFromDate(date)}
              minimumDate={new Date(1899, 0, 1)}
              maximumDate={toDate}
              showCalendarButton={true}
            />
            <IM.Text style={{ marginLeft: 10 }}>{i18n.t('TO_DATE')}:</IM.Text>
            <DateInput
              value={toDate.toDateString()}
              onChangeDate={date => date && setToDate(date)}
              minimumDate={fromDate}
              maximumDate={new Date(2199, 0, 1)}
              showCalendarButton={true}
            />
            {currentOrganization?.organizationId === 303 && <IM.Button title={i18n.t('IMPORT')} onPress={handleImport} style={{ marginLeft: 100 }} />}
          </IM.View>

          <TableWrapper
            tableContainerSpacing={'all'}
            headerData={lotsTableHeader}
            tableData={lotsTableData}
            subTableDataKey="trunks"
            subTableHeaderData={trunksTableHeader}
            hasPaging
            maxTableHeight={730}
            showNewItemRow
            onNewItemPress={handleNewPackagePress}
            hasEditAndDelete
            onEditPress={handlePackageEdit}
            onDeletePress={handlePackageDelete}
            flexArray={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
            subtableFlexArray={[1, 1, 1, 1, 1]}
            onSubtableEditPress={handleTrunkEdit}
            onSubtableDeletePress={handleTrunkDelete}
            onSubtableAddPress={handleTrunkAdd}
          />
        </IM.View>
      </ScrollView>
      <LotCreateModal controller={createLotModal} onSaveCallback={onNewLotCreated} />
      <TrunkCreateModal controller={createTrunkModal} onSaveCallback={onNewTrunkCreated} />
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
  datePickerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: -20,
    marginLeft: 5,
    marginTop: 5,
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
})
