export const ADD_LOT_BUTTON_ID = 'AddLotButtonId'
export const ADD_PACKAGE_BUTTON_ID = 'AddPackageButtonId'
export const ADD_CUSTOMER_BUTTON_ID = 'AddCustomerButtonId'
export const OPEN_KEYBOARD_BUTTON_ID = 'OpenKeyboardButtonId'
export const CREATE_USER_BUTTON_ID = 'CreateUserButtonId'
export const CREATE_TYPOLOGY_BUTTON_ID = 'AddTypologyButtonId'
export const CREATE_WOOD_BUTTON_ID = 'AddWoodButtonId'
export const CREATE_ORIGIN_BUTTON_ID = 'AddOriginButtonId'
export const CREATE_SAWMILL_BUTTON_ID = 'AddSawmillButtonId'
export const CREATE_QUALITY_BUTTON_ID = 'AddQualityButtonId'
export const CREATE_GRADE_BUTTON_ID = 'AddGradeButtonId'
export const CREATE_CUSTOMER_BUTTON_ID = 'AddCustomerButtonId'
export const CREATE_MEASURER_BUTTON_ID = 'AddMeasurerButtonId'
export const CREATE_TRUNK_BUTTON_ID = 'AddTrunkButtonId'
export const CREATE_WOOD_TYPE_BUTTON_ID = 'AddWoodTypeButtonId'
