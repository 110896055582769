import { IM, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import TableUtils from '../../components/table/utils/TableUtils'
import { useAuthentication } from '../../contexts/AuthenticationContext'
import { useData } from '../../contexts/DataContext'
import useDeleteAlert from '../../hooks/useDeleteAlert'
import ReductionTypeCreateModal from '../../modals/ReductionTypeCreateModal'
import { ReductionType, ReductionTypeFormula } from '../../types/types'
import { ReductionTypeCreateViewProps } from '../ReductionTypeCreate/ReductionTypeCreateView'

export default function ReductionFormulasView({
  setIsLoading,
  isLoading,
}: {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
}) {
  const { i18n } = useLanguage()
  const deleteAlert = useDeleteAlert()
  const { reductionTypes, loadReductionTypes, reductionTypesLoaded } = useData()
  const { isAdmin } = useAuthentication()

  const [reductionTypeSearchValue, setReductionTypeSearchValue] = useState<string | undefined>(undefined)

  const createReductionTypeModal = useModalController<ReductionTypeCreateViewProps>()

  useEffect(() => {
    if (reductionTypesLoaded) return
    loadData()
  }, [])

  function loadData() {
    setIsLoading(true)
    loadReductionTypes(isAdmin)
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  const handleNewReductionTypePress = () => {
    createReductionTypeModal.show({})
  }

  const handleReductionTypeEdit = (reductionType: ReductionType) => {
    const selectedReductionType = reductionTypes.find(c => c.reductionTypeId === reductionType.reductionTypeId)
    createReductionTypeModal.show({ editId: selectedReductionType?.reductionTypeId.toString() })
  }

  const handleReductionTypeDelete = (reductionType: ReductionType) => {
    const selectedReductionType = reductionTypes.find(c => c.reductionTypeId === reductionType.reductionTypeId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteReductionType({ reductionTypeId: Number(selectedReductionType?.reductionTypeId) })
            .then(() => {
              loadData()
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  const onNewReductionTypeCreated = (_reductionType: ReductionType | ReductionTypeFormula | unknown) => {
    createReductionTypeModal.close()
    loadData()
  }

  const reductionTypesTableHeader: TableHeaderData<ReductionType>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'reductionTypeName' },
      { title: i18n.t('CODE'), property: 'reductionTypeCode' },
    ],
    [i18n]
  )

  const reductionTypesTableData: ReductionType[] = useMemo(() => {
    return TableUtils<ReductionType>().filterData(reductionTypes, reductionTypeSearchValue)
  }, [reductionTypes, reductionTypeSearchValue])

  return (
    <>
      <IM.View spacing="all" style={styles.container}>
        <TableWrapper
          tableTitle={i18n.t('REDUCTION_FORMULAS')}
          tableContainerSpacing={'all'}
          headerData={reductionTypesTableHeader}
          tableData={reductionTypesTableData}
          maxTableHeight={750}
          showNewItemRow={true}
          hasPaging
          rowsPerPage={15}
          onNewItemPress={handleNewReductionTypePress}
          hasEditAndDelete={true}
          onEditPress={handleReductionTypeEdit}
          onDeletePress={handleReductionTypeDelete}
        />
      </IM.View>
      <ReductionTypeCreateModal controller={createReductionTypeModal} onCreateReductionType={onNewReductionTypeCreated} />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
})
