import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { useData } from '../../contexts/DataContext'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection, WoodType } from '../../types/types'
import WoodTypeCard from '../cards/WoodTypeCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: WoodType | undefined) => void
  required?: boolean
  selectedWoodTypeId?: number
  showReduction?: boolean
}

export default function WoodTypeSelector({ onChange, selectedWoodTypeId, showReduction, ...props }: Props) {
  const { i18n } = useLanguage()

  const { woodTypes } = useData()
  const [search, setSearch] = useState('')

  const filteredData = useDataSearchFilter(woodTypes, search, ['woodTypeName', 'woodTypeId', 'woodTypeCode'])

  const render = ({ item }: SectionListRenderItemInfo<WoodType, ListSection<WoodType>>, onPress?: () => void) => {
    return <WoodTypeCard woodType={item} onPress={onPress} selected={item.woodTypeId === selectedWoodTypeId} spacing={['bottom', 'horizontal']} />
  }

  const handleOnChange = (newValue: WoodType | undefined) => {
    onChange(newValue)
  }

  //   const handleSave = (inputValue: string) => {
  //     saveWoodType(inputValue).catch(error => {
  //       console.error('Failed to save customer', error)
  //     })
  //   }

  //   const saveWoodType = async (inputValue: string) => {
  //     try {
  //       if (organizationId === undefined) return
  //       await api.postWoodType({ woodTypeName: inputValue })
  //       const res = await api.getWoodTypes({ organizationId: organizationId })
  //       setWoodTypes(res)
  //     } catch (error) {
  //       console.error('Failed to save customer', error)
  //     }
  //   }

  const selectedWoodType = useMemo(() => woodTypes?.find(elem => elem.woodTypeId === selectedWoodTypeId), [woodTypes, selectedWoodTypeId])

  return (
    <SelectInput
      id={'woodTypeId'}
      data={filteredData}
      value={selectedWoodType}
      loading={false}
      refresh={undefined}
      title={i18n.t('WOOD_TYPES')}
      screenTitle={i18n.t('WOOD_TYPES')}
      noDataMessage={i18n.t('NO_WOOD_TYPES')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.woodTypeName}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      // onSave={(inputValue: string) => handleSave(inputValue)}
      //   onSaveWoodType={(inputValue: string, reduction?: number) => handleSave(inputValue, reduction)}
      showReduction={showReduction}
      searchField="woodTypeName"
      {...props}
    />
  )
}
