import { IM, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'

import { Screen } from '../components/screen/Screen'
import { FormProvider } from '../contexts/FormContext'
import { PackageCreationContextProvider } from '../contexts/PackageCreationContext'
import BoardCreateView from '../screens/Package/BoardCreateView'
import BoardCreateHeader from '../screens/PackageCreate/BoardCreateHeader'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type BoardCreateModalProps = {
  controller: ModalController<BoardCreateModalControllerData>
  onSaveCallback?: () => void
  // lotId?: number
}

export type BoardCreateModalControllerData = {
  tmpId?: string
  editBoardId?: number
  packageId?: number
}

export default function BoardCreateModal({ controller, onSaveCallback }: BoardCreateModalProps) {
  const ref = createRef<ModalScreenRef>()
  const [isShown, setIsShown] = useState(false)
  const { i18n } = useLanguage()

  const isModal = controller !== undefined
  const isEditModeBoardId = controller?.data?.editBoardId !== undefined
  const isEditModeTmpId = controller?.data?.tmpId !== undefined

  const editMode = isEditModeBoardId || isEditModeTmpId

  const handleClose = () => {
    controller.close()
  }

  const handleSave = () => {
    if (onSaveCallback) onSaveCallback()
    controller.close()
  }

  useEffect(() => {
    if (!controller.isShown) setIsShown(false)
  }, [controller.isShown])

  return (
    <BaseServiceModal controller={controller} childRef={ref} onModalShow={() => setIsShown(true)}>
      <PackageCreationContextProvider>
        <Screen>
          <Screen.Header
            goBack={() => handleClose()}
            backHandlerCallback={handleClose}
            isModal={isModal}
            title={editMode ? i18n.t('EDIT') : i18n.t('NEW')}
          />
          <Screen.Header goBack={handleClose} backHandlerCallback={() => controller.close}>
            <BoardCreateHeader />
          </Screen.Header>
          <Screen.Content>
            <FormProvider>
              {isShown && (
                <FormProvider>
                  <BoardCreateView editId={controller.data?.editBoardId} onSave={handleSave} packageId={controller?.data?.packageId} />
                </FormProvider>
              )}
              {!isShown && <IM.LoadingSpinner isVisible={true} />}
            </FormProvider>
          </Screen.Content>
        </Screen>
      </PackageCreationContextProvider>
    </BaseServiceModal>
  )
}
