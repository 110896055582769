import { IM, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'

import { Screen } from '../components/screen/Screen'
import { FormProvider } from '../contexts/FormContext'
import { LotCreationContextProvider } from '../contexts/LotCreationContext'
import TrunkCreateHeader from '../screens/LotCreate/TrunkCreateHeader'
import TrunkCreateView from '../screens/Lots/TrunkCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type TrunkCreateModalProps = {
  controller: ModalController<TrunkCreateModalControllerData>
  onSaveCallback?: () => void
  // lotId?: number
}

export type TrunkCreateModalControllerData = {
  tmpId?: string
  editTrunkId?: number
  lotId?: number
}

export default function TrunkCreateModal({ controller, onSaveCallback }: TrunkCreateModalProps) {
  const ref = createRef<ModalScreenRef>()
  const [isShown, setIsShown] = useState(false)
  const { i18n } = useLanguage()

  const isModal = controller !== undefined
  const isEditModeTrunkId = controller?.data?.editTrunkId !== undefined
  const isEditModeTmpId = controller?.data?.tmpId !== undefined

  const editMode = isEditModeTrunkId || isEditModeTmpId

  const handleClose = () => {
    controller.close()
  }

  const handleSave = () => {
    if (onSaveCallback) onSaveCallback()
    controller.close()
  }

  useEffect(() => {
    if (!controller.isShown) setIsShown(false)
  }, [controller.isShown])

  return (
    <BaseServiceModal controller={controller} childRef={ref} onModalShow={() => setIsShown(true)}>
      <LotCreationContextProvider>
        <Screen>
          <Screen.Header
            goBack={() => handleClose()}
            backHandlerCallback={handleClose}
            isModal={isModal}
            title={editMode ? i18n.t('EDIT') : i18n.t('NEW')}
          />
          <Screen.Header goBack={handleClose} backHandlerCallback={() => controller.close}>
            <TrunkCreateHeader />
          </Screen.Header>
          <Screen.Content>
            <FormProvider>
              {isShown && (
                <FormProvider>
                  <TrunkCreateView editId={controller.data?.editTrunkId} onSave={handleSave} lotId={controller?.data?.lotId} />
                </FormProvider>
              )}
              {!isShown && <IM.LoadingSpinner isVisible={true} />}
            </FormProvider>
          </Screen.Content>
        </Screen>
      </LotCreationContextProvider>
    </BaseServiceModal>
  )
}
