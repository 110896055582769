import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { PostWoodTypeRequest } from '../../apis/apiRequestTypes'
import AnimatedButton from '../../components/AnimatedButton'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import TextInput from '../../components/TextInput'
import { CREATE_WOOD_TYPE_BUTTON_ID } from '../../constants/ButtonIds'
import { ThemeColorExpanded, UploadStatus } from '../../types'
import { WoodType } from '../../types/types'

const buttonAnimationValue = new Animated.Value(0)

export interface WoodTypeCreateViewProps {
  editId?: string
  onCreateWoodType?: (woodType: WoodType) => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
}

type ValidateWoodTypeField = 'woodTypeName' | 'woodTypeCode'
type RequiredField = { value: string | undefined; field: ValidateWoodTypeField }

const WoodTypeCreateView: React.FC<WoodTypeCreateViewProps & Props> = ({ editId, onCreateWoodType, onUploadStatus }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const [woodType, setWoodType] = useState<Partial<PostWoodTypeRequest>>({})
  const [missingFields, setMissingFields] = useState<ValidateWoodTypeField[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<Partial<PostWoodTypeRequest>>({})

  useEffect(() => {
    if (editId) {
      loadEditWoodType().catch(console.error)
    }
  }, [editId])

  async function loadEditWoodType() {
    setIsLoading(true)
    await api
      .getWoodType({ woodTypeId: Number(editId) })
      .then(response => {
        const editWoodType = {
          woodTypeCode: response.woodTypeCode,
          woodTypeName: response.woodTypeName,
        }
        setWoodType(editWoodType)
        setInitialValue(editWoodType)
      })
      .catch(console.error)
      .then(() => setIsLoading(false))
  }

  function handleChange(value: Partial<PostWoodTypeRequest>) {
    setWoodType(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    const requiredFields: RequiredField[] = [
      { value: woodType.woodTypeName, field: 'woodTypeName' },
      { value: woodType.woodTypeCode, field: 'woodTypeCode' },
    ]
    const notFilledFields = requiredFields.filter(field => field.value === undefined || field.value === '').map(field => field.field)
    setMissingFields(notFilledFields)
    if (notFilledFields.length > 0) {
      onUploadStatus('mandatoryMissing')
    }
    return notFilledFields.length === 0
  }

  function haveValuesChanged() {
    return JSON.stringify(woodType) !== JSON.stringify(initialValue)
  }

  useEffect(() => {
    if (haveValuesChanged()) {
      onUploadStatus('waiting')
    }
    if (!showMissingFields) return
    if (!validateForm()) return
  }, [woodType, showMissingFields])

  const handlePress = () => {
    setShowMissingFields(true)
    setIsLoading(true)
    onUploadStatus('uploading')
    handleCreate()
      .catch(console.error)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCreate = async () => {
    //VALIDATION
    // check if all required fields are filled
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }

    const woodTypeBody: WoodType = {
      woodTypeId: woodType.woodTypeId ?? 0,
      woodTypeCode: woodType.woodTypeCode ?? '',
      woodTypeName: woodType.woodTypeName ?? '',
    }

    //TODO: check return type of putWoodType
    //UPDATE woodType
    if (editId) {
      await api
        .putWoodType(woodTypeBody)
        .then(response => {
          console.log(response)
          onUploadStatus('done')
        })
        .catch(error => {
          console.log(error)
          alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
      return
    }

    //CREATE woodType
    await api
      .postWoodType(woodTypeBody)
      .then(response => {
        //TODO
        onCreateWoodType?.(response)
        onUploadStatus('done')
      })
      .catch(error => {
        console.log(error)
        alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
          {
            text: i18n.t('OK'),
            style: 'default',
            onPress: () => {
              return
            },
          },
        ])
      })
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <TextInput
              required
              title={i18n.t('NAME')}
              placeholder={i18n.t('NAME')}
              value={woodType.woodTypeName ?? ''}
              onChangeText={value => handleChange({ woodTypeName: value })}
              spacing={'all'}
              error={missingFields.includes('woodTypeName')}
            />
            <TextInput
              required
              title={i18n.t('CODE')}
              placeholder={i18n.t('CODE')}
              value={woodType.woodTypeCode ?? ''}
              onChangeText={value => handleChange({ woodTypeCode: value })}
              spacing={'all'}
              error={missingFields.includes('woodTypeCode')}
            />
          </ScrollViewForm>

          {Platform.OS === 'web' ? (
            <IM.View style={styles.buttonContainer}>
              <PressableTextIcon
                icon={['fal', 'floppy-disk']}
                alignIcon="right"
                pressableStyle={styles.webButton}
                style={{ color: theme.button.icon }}
                iconColor={theme.button.icon}
                onPress={() => handlePress()}>
                {i18n.t('SAVE')}
              </PressableTextIcon>
            </IM.View>
          ) : (
            <AnimatedButton
              style={styles.button}
              id={CREATE_WOOD_TYPE_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'check']}
              iconSize={50}
              onPress={() => handlePress()}
            />
          )}
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default WoodTypeCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
